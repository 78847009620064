<template>
  <div>
    <el-dialog
      :visible.sync="modalShow"
      :close-on-click-modal="false"
      :width="modalWidth"
      top="5px"
      append-to-body
      @close="modalClose"
    >
      <template #title>
        <h3 class="m-0">請休假稽核</h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <table class="table table-bordered table-striped table-hover display">
          <tbody>
            <tr>
              <td style="width: 15%">姓名</td>
              <td style="width: 35%">{{ row.user && row.user.name }}</td>
              <td style="width: 15%">部門</td>
              <td style="width: 35%">{{ row.user && row.user.department.name }}</td>
            </tr>
            <tr>
              <td>假別</td>
              <td>{{ row.leave && row.leave.name }}</td>
              <td>項目</td>
              <td>{{ row.leave && row.leave.items[row.item_id].title }}</td>
            </tr>
            <tr v-if="availableHours > 1 || (row.leave && row.leave.is_separate)">
              <td>可請總時數</td>
              <td>
                {{ `${availableHours} 小時` }}
                {{
                  availableHours / row.basic_hours > 0
                    ? `( ${Math.floor(availableHours / row.basic_hours)} 天 ${availableHours % row.basic_hours} 小時 )`
                    : ''
                }}
              </td>
              <td>已請總時數</td>
              <td>
                {{ `${usedHours} 小時` }}
                {{
                  usedHours / row.basic_hours > 0
                    ? `( ${Math.floor(usedHours / row.basic_hours)} 天 ${usedHours % row.basic_hours} 小時 )`
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <td>開始時間</td>
              <td>{{ row.start_at }}</td>
              <td>結束時間</td>
              <td>{{ row.end_at }}</td>
            </tr>
            <tr>
              <td>狀態</td>
              <td>
                <b-badge :variant="colors[row.is_approved]">{{ leaveApplyStatus[row.is_approved] }}</b-badge>
              </td>
              <td>請假時數</td>
              <td>
                {{ `${row.hours} 小時` }}
                {{
                  row.hours / row.basic_hours > 0
                    ? `( ${Math.floor(row.hours / row.basic_hours)} 天 ${row.hours % row.basic_hours} 小時 )`
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <td>申請時間</td>
              <td>{{ row.created_at }}</td>
              <td>核准時間</td>
              <td>{{ row.is_approved == 1 ? row.updated_at : '' }}</td>
            </tr>
            <tr>
              <td>備註</td>
              <td colspan="3">
                {{ row.comment }}
                <el-tooltip v-if="row.media && row.media.length > 0" placement="top" content="點擊查看請假證明">
                  <a href="#" @click.prevent="$emit('show-proofs', row.media)"><fa icon="file-image" /> 請假證明</a>
                </el-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <h3 class="d-flex">
          <span>主管審核</span>
          <div v-if="[2, 3].includes(row.is_approved)" class="ml-auto">
            <b-btn size="sm" variant="success" @click.prevent="onAllApprove(row.id)">
              <fa icon="check-circle" fixed-width />全數核准
            </b-btn>
            <b-btn class="ml-1" size="sm" variant="danger" @click.prevent="onAllReject(row.id)">
              <fa icon="times-circle" fixed-width />全數駁回
            </b-btn>
          </div>
        </h3>
        <table class="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <td class="text-center" style="width: 50px">順序</td>
              <td style="width: 90px">主管</td>
              <td class="text-center" style="width: 80px">狀態</td>
              <td style="width: 190px">審核時間</td>
              <td>主管評語</td>
              <td style="width: 90px">操作</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in row.audits" :key="index" class="vm">
              <td class="text-center">{{ index + 1 }}</td>
              <td>{{ item.audit && item.audit.name }}</td>
              <td class="text-center">
                <b-badge :variant="colors[item.is_approved]">{{ leaveAuditStatus[item.is_approved] }}</b-badge>
              </td>
              <td>{{ item.is_approved == 2 ? '' : item.updated_at }}</td>
              <td>
                <el-input v-model="item.comment" />
              </td>
              <td>
                <b-btn-group
                  v-if="
                    (index == 0 && item.is_approved == 2) ||
                    (index > 0 && row.audits[index - 1].is_approved == 1 && item.is_approved == 2)
                  "
                >
                  <b-btn size="sm" variant="success" @click.prevent="onApprove(item)">
                    <fa icon="check-circle" fixed-width />
                  </b-btn>
                  <b-btn size="sm" variant="danger" @click.prevent="onReject(item)">
                    <fa icon="times-circle" fixed-width />
                  </b-btn>
                </b-btn-group>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />關閉</b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { leaveApplyStatus, leaveAuditStatus, colors } from '@/plugins/const'
export default {
  name: 'LeaveApplyAudit',
  mixins: [modify],
  data() {
    return {
      row: {},
      rules: {},
      modalMaxWidth: 800,
      changed: 0,
      leaveApplyStatus,
      leaveAuditStatus,
      colors,
    }
  },
  computed: {
    availableHours() {
      let hours = 0

      if (this.row.leave && this.row.event) {
        hours =
          this.row.leave_id == 1
            ? this.row.event.hours
            : this.row.leave.items[this.row.item_id].days * this.row.basic_hours
      }

      return hours
    },
    usedHours() {
      let hours = 0

      if (this.row.event && this.row.event.used_hours) {
        hours = this.row.event.used_hours
      }

      return hours
    },
  },
  methods: {
    ...mapActions('leaveApply', { fetchOne: 'fetchOne', doAllApprove: 'doApprove', doAllReject: 'doReject' }),
    ...mapActions('leaveApplyAudit', ['doApprove', 'doReject']),
    fetch(id) {
      this.fetchOne(id).then((result) => {
        this.$set(this.$data, 'row', result)
      })
    },
    onApprove(item) {
      this.$confirm(`是否確定核准？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doApprove({ id: item.id, comment: item.comment }).then(() => {
            this.changed++
            this.fetch(this.row.id)
          })
        })
        .catch(() => {})
    },
    onAllApprove(id) {
      this.$confirm(`是否確定全數核准？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doAllApprove({ id, comment: null }).then(() => {
            this.changed++
            this.fetch(this.row.id)
          })
        })
        .catch(() => {})
    },
    onReject(item) {
      this.$confirm(`是否確定駁回？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doReject({ id: item.id, comment: item.comment }).then(() => {
            this.changed++
            this.fetch(this.row.id)
          })
        })
        .catch(() => {})
    },
    onAllReject(id) {
      this.$confirm(`是否確定全數駁回？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doAllReject({ id, comment: null }).then(() => {
            this.changed++
            this.fetch(this.row.id)
          })
        })
        .catch(() => {})
    },
    modalClose() {
      if (this.changed) {
        this.$emit('done')
        this.changed = 0
      }
    },
  },
}
</script>
