<template>
  <div class="row">
    <div v-for="(item, key) in innerImages" :key="key" :class="'mb-3 col-sm-' + column">
      <img :src="item.original_url" class="img-thumbnail" @click="onPreview(item.original_url)" />
      <div class="text-danger close"><fa icon="times-circle" fixed-width @click="onRemove(item, key)" /></div>
    </div>

    <div v-if="!pass && progress !== 0" :class="'mb-3 col-sm-' + column">
      <el-progress type="circle" :percentage="progress" :status="progressStatus" />
    </div>

    <div :class="'mb-3 col-sm-' + column">
      <el-upload
        ref="upload"
        accept="image/*"
        name="photo"
        multiple
        list-type="picture-card"
        :show-file-list="false"
        :action="'/api' + action"
        :on-change="onUploadChange"
        :on-success="onUploadSuccess"
        :on-error="onUploadError"
        :on-progress="onUploadProgress"
      >
        <el-button type="primary">上傳檔案</el-button>
      </el-upload>
    </div>

    <el-dialog title="" :visible.sync="isPreview" append-to-body width="60%">
      <img style="width: 100%" :src="previewUrl" @click="isPreview = false" />
    </el-dialog>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'ImageUpload',
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
    action: {
      type: String,
      default: '/image',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    column: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      progress: 0,
      pass: false,
      isPreview: false,
      previewUrl: '',
      innerImages: [],
    }
  },
  computed: {
    progressStatus() {
      if (this.pass) {
        return 'success'
      } else if (this.pass === false) {
        return 'exception'
      } else {
        return 'warning'
      }
    },
  },
  watch: {
    // Handles internal model changes.
    innerImages(newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerImages = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerImages = this.value
    }
  },
  methods: {
    onUploadProgress(e) {
      let percent = Number(e.percent)
      this.progress = isNaN(percent) ? 0 : Math.floor(percent)
    },
    onUploadChange(file) {
      if (file.status === 'ready') {
        this.pass = null
        this.progress = 0
      } else if (file.status === 'fail') {
        this.$message.error('圖片上傳發生錯誤，請重新上傳')
      }
      // this.$emit('imageChange', this.imageList)
    },
    onUploadSuccess(result) {
      this.pass = true
      this.$message.success('圖片上傳完成')
      this.innerImages.push(result)
      console.log(result)
    },
    onUploadError(err) {
      this.$message.error(err)
      this.pass = false
      this.progress = 0
    },
    onPreview(url) {
      if (url) {
        this.previewUrl = url
        this.isPreview = !this.isPreview
      }
    },
    async onRemove(image, i) {
      if (!image.original_url) {
        return false
      }

      this.$confirm('是否刪除？', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (image.id) {
            axios.delete(`${this.action}/${image.id}`)
          }
          this.innerImages.splice(i, 1)
          this.$message.success('刪除完成')
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>
