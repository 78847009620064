const app = {
  namespaced: true,
  state: {
    sidebar: !!+localStorage.getItem('sidebar'),
    device: localStorage.getItem('device') || 'desktop',
    cachedViews: [],
    windowWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  },
  mutations: {
    TOGGLE_SIDEBAR: (state) => {
      state.sidebar = !state.sidebar
      localStorage.setItem('sidebar', state.sidebar)
    },
    CLOSE_SIDEBAR: (state) => {
      state.sidebar = false
      localStorage.setItem('sidebar', false)
    },
    SET_DEVICE: (state, device) => {
      state.device = device
      localStorage.setItem('device', device)
    },
    ADD_CACHED_VIEW: (state, view) => {
      if (!state.cachedViews.includes(view.name)) {
        state.cachedViews.push(view.name)
      }
    },
    SET_WINDOW_WIDTH: (state, width) => {
      state.windowWidth = +width
    },
  },
  actions: {
    async toggleSideBar({ commit }) {
      await commit('TOGGLE_SIDEBAR')
    },
    async closeSidebar({ commit }) {
      await commit('CLOSE_SIDEBAR')
    },
    async setDevice({ commit }, device) {
      await commit('SET_DEVICE', device)
      if (device === 'mobile') {
        await commit('CLOSE_SIDEBAR')
      }
    },
    async addCachedView({ commit }, view) {
      await commit('ADD_CACHED_VIEW', view)
    },
    async updateWindowWidth({ commit }) {
      let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      await commit('SET_WINDOW_WIDTH', width)
      await commit('SET_DEVICE', width < 992 ? 'mobile' : 'desktop')
    },
  },
  getters: {
    sidebar: (state) => state.sidebar,
    device: (state) => state.device,
    cachedViews: (state) => state.cachedViews,
    windowWidth: (state) => state.windowWidth,
  },
}

export default app
