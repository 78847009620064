import axios from '@/plugins/axios'

const url = 'holidays'
const holiday = {
  namespaced: true,
  actions: {
    async fetchAll({}, params) {
      let { data } = await axios.get(`/${url}`, { params })
      return data
    },
    async fetchOne({}, id) {
      let { data } = await axios.get(`/${url}/${id}`)
      return data
    },
    async doCreate({}, params) {
      let { data } = await axios.post(`/${url}`, params)
      return data
    },
    async doUpdate({}, params) {
      let { data } = await axios.put(`/${url}/${params.id}`, params)
      return data
    },
    async doDelete({}, id) {
      let { data } = await axios.delete(`/${url}/${id}`)
      return data
    },
    async doFetch({}) {
      await axios.get(`/${url}/fetch`)
    },
  },
}

export default holiday
