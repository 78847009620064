import axios from '@/plugins/axios'
import { reject } from 'lodash-es'

const url = 'leaves'
const leave = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_LEAVE: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }, params) {
      let { data } = await axios.get(`/${url}/all`, { params })
      commit('SET_LEAVE', data)
      return data
    },
    async fetchPage({}, params) {
      let { data } = await axios.get(`/${url}`, { params })
      return data
    },
    async fetchOne({}, id) {
      let { data } = await axios.get(`/${url}/${id}`)
      return data
    },
    async doCreate({}, params) {
      let { data } = await axios.post(`/${url}`, params)
      return data
    },
    async doUpdate({}, params) {
      let { data } = await axios.put(`/${url}/${params.id}`, params)
      return data
    },
    async doDelete({}, id) {
      let { data } = await axios.delete(`/${url}/${id}`)
      return data
    },
  },
  getters: {
    all: (state) => state.data,
    user:
      (state) =>
      (sex, annual = 1) => {
        let leaves = sex == 1 ? state.data.filter((leave) => leave.is_female_only == 0) : state.data
        if (annual == 0) {
          leaves = reject(leaves, (leave) => leave.id == 1)
        }
        return leaves
      },
    name: (state) => (id) => {
      return state.data.find((l) => l.id == +id)?.name || ''
    },
  },
}

export default leave
