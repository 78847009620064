<template>
  <nav class="app-nav navbar navbar-expand navbar-light bg-light">
    <ul class="navbar-nav">
      <div :class="{ 'is-active': sidebar }" class="hamburger align-self-center text-secondary" @click="toggleSideBar">
        <fa icon="caret-square-right" size="2x" />
      </div>
    </ul>

    <ul class="navbar-nav d-none d-md-flex">
      <el-breadcrumb separator="/" class="align-self-center">
        <template v-for="(item, index) in items">
          <el-breadcrumb-item :key="index" :to="item.meta.breadcrumb === false ? null : item.path">
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item align-self-center">
        <a class="nav-link" href="/mobile"><fa icon="mobile-alt" fixed-with /></a>
      </li>
      <li class="nav-item align-self-center">
        <a class="nav-link" href="#" @click.prevent="logout"><fa icon="sign-out-alt" fixed-with /> 登出</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Navbar',
  data() {
    return {
      items: [],
    }
  },
  computed: {
    ...mapState('app', ['sidebar']),
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    },
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    ...mapActions('app', ['toggleSideBar']),
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.name)
      const first = matched[0]
      if (first && first.name !== 'Dashboard') {
        const dashboard = this.$router.resolve('/dashboard').resolved.matched.slice(-1)[0]
        matched = [dashboard].concat(matched)
      }
      this.items = matched.filter((item) => item.meta && item.meta.title)
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        location.assign('/login')
      })
    },
  },
}
</script>
