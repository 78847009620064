import axios from '@/plugins/axios'

const url = `shifts`
const shift = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_SHIFT: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }) {
      let { data } = await axios.get(`/${url}`)
      commit('SET_SHIFT', data)
      return data
    },
    async fetchPage({}, params) {
      let { data } = await axios.get(`/${url}`, { params })
      return data
    },
    async fetchOne({}, id) {
      const { data } = await axios.get(`/${url}/${id}`)
      return data
    },
    async doCreate({}, params) {
      const { data } = await axios.post(`/${url}`, params)
      return data
    },
    async doUpdate({}, params) {
      const { data } = await axios.put(`/${url}/${params.id}`, params)
      return data
    },
    async doDelete({}, id) {
      const { data } = await axios.delete(`/${url}/${id}`)
      return data
    },
    async doChange({}, params) {
      const { data } = await axios.post(`/${url}/change/`, params)
      return data
    },
    async checkUnique({}, name) {
      let { data } = await axios.post(`/${url}/unique`, { name })
      return data
    },
  },
  getters: {
    all: (state) => state.data,
    serial: (state) => (shiftId, attendanceId) => {
      return state.data.find((s) => s.id == +shiftId)?.period[attendanceId]
    },
  },
}

export default shift
