<template>
  <section class="app-main">
    <transition name="fade" mode="out-in" appear>
      <keep-alive :include="cached" :max="5">
        <router-view />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cached() {
      return this.$store.getters['app/cachedViews']
    },
  },
}
</script>
