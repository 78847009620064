import { initializeApp } from 'firebase/app'
import { getMessaging, onMessage, isSupported } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyAeTJ1j711q8vbpJ-Fm2KO3-6C2rqS4QAQ',
  authDomain: 'goldenjade-dbf11.firebaseapp.com',
  databaseURL: 'https://goldenjade-dbf11.firebaseio.com',
  projectId: 'goldenjade-dbf11',
  storageBucket: 'goldenjade-dbf11.appspot.com',
  messagingSenderId: '409271914575',
  appId: '1:409271914575:web:8b944816ab01ec0a1bda3a',
  measurementId: 'G-EMZK79SCVF',
}

const app = initializeApp(firebaseConfig)

let messaging = null

isSupported().then((result) => {
  if (result) {
    const messaging = getMessaging(app)
    onMessage(messaging, (payload) => {
      console.log('[firebase-messaging-sw.js] onMessage ', payload)
      const options = {
        body: payload.notification.body,
        icon: '/img/icons/android-chrome-192x192.png',
        click_action: payload.data.click_action || '/mobile/dashboard',
      }

      navigator.serviceWorker.getRegistration().then((reg) => {
        reg.showNotification(payload.notification.title, options)
      })
    })
  }
})

export default messaging
