import axios from '@/plugins/axios'

const url = 'leave-applies'
const leaveApply = {
  namespaced: true,
  actions: {
    async fetchMyLeaveApplies({}, params) {
      let { data } = await axios.get(`/${url}/me`, { params })
      return data
    },
    async fetchPage({}, params) {
      let { data } = await axios.get(`/${url}`, { params })
      return data
    },
    async fetchOne({}, id) {
      let { data } = await axios.get(`/${url}/${id}`)
      return data
    },
    async doCreate({}, params) {
      let { data } = await axios.post(`/${url}`, params)
      return data
    },
    async doUpdate({}, params) {
      let { data } = await axios.put(`/${url}/${params.id}`, params)
      return data
    },
    async doDelete({}, id) {
      let { data } = await axios.delete(`/${url}/${id}`)
      return data
    },
    async doDeleteProof({}, id) {
      let { data } = await axios.delete(`/${url}/delete-proof/${id}`)
      return data
    },
    async doApprove({}, { id, comment }) {
      let { data } = await axios.put(`/${url}/approve/${id}`, { is_approved: 1, comment: comment })
      return data
    },
    async doReject({}, { id, comment }) {
      let { data } = await axios.put(`/${url}/approve/${id}`, { is_approved: 0, comment: comment })
      return data
    },
    async checkEvent({}, { userId, leaveId, itemId }) {
      let { data } = await axios.get(`/${url}/check-event/${userId}/${leaveId}/${itemId}`)
      return data
    },
    async checkPeriod({}, { userId, leaveId, start, end }) {
      let { data } = await axios.get(`/${url}/check-period/${userId}/${leaveId}?period[]=${start}&period[]=${end}&`)
      return data
    },
  },
}

export default leaveApply
