<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  mounted() {
    // const sw = navigator?.serviceWorker
    // if (sw && this.$messaging && process.env.NODE_ENV == 'production') {
    // if (sw && this.$messaging) {
    //   sw.getRegistration().then((reg) => {
    //     this.$messaging.useServiceWorker(reg)
    //   })
    // }
    document.addEventListener('swUpdated', this.showRefreshUI)
  },
  beforeDestroy() {
    document.removeEventListener('swUpdated', this.showRefreshUI)
  },
  methods: {
    showRefreshUI() {
      this.$notify.success({
        title: '系統更新通知',
        message: '目前已有新的功能更新，請點擊此通知刷新頁面更新。',
        position: 'bottom-right',
        duration: 10000,
        onClick: () => window.location.reload(true),
        onClose: () => window.location.reload(true),
      })
    },
  },
}
</script>
