<template>
  <div>
    <el-dialog
      :visible.sync="modalShow"
      :close-on-click-modal="false"
      :width="modalWidth"
      top="5px"
      append-to-body
      @open="modalOpen"
    >
      <template #title>
        <h3 class="m-0">
          {{ actionText + '出勤班別' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="120px" size="medium">
        <div class="card">
          <div class="card-body p-3">
            <!-- <div v-if="workHours > 8 && row.is_overtime" class="alert alert-danger" role="alert">
              每日基本工時為 {{ workHours }} 小時，超過基本 8
              小時，將採用「變形工時」計算。每月空班時數（免出勤）如果加班將以「休息日」計算。
            </div> -->
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="班別名稱" prop="name">
                <el-input v-model.trim="row.name" placeholder="班別名稱" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="出勤考核" prop="is_audit">
                <el-switch
                  v-model="row.is_audit"
                  :disabled="row.id == 1"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                  @change="onAudit"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="班別種類" prop="type">
                <el-select v-model="row.type" placeholder="- 班別種類 -">
                  <el-option v-for="(item, index) in shiftTypes" :key="index" :label="item" :value="Number(index)" />
                </el-select>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="特休假" prop="is_annual_leave">
                <el-switch
                  v-model="row.is_annual_leave"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="有"
                  inactive-text="無"
                  @change="onAnnual"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="加班費" prop="is_overtime">
                <el-switch
                  v-model="row.is_overtime"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="有"
                  inactive-text="無"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="彈性上下班" prop="is_flextime">
                <el-switch
                  v-model="row.is_flextime"
                  :disabled="row.is_audit == 0"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item
                :rules="[{ required: true, message: '請選擇未休假', trigger: 'blur' }]"
                class="col-md-6 col-lg-4"
                label="未休假處理"
                prop="is_grand_total"
              >
                <el-select v-model.number="row.is_grand_total" placeholder="未休假處理方式">
                  <el-option
                    v-for="(item, index) in shiftGrandTotal"
                    :key="index"
                    :label="item"
                    :value="Number(index)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="天災假"
                prop="is_disaster_day_discount"
                :rules="[{ required: true, message: '請選擇', trigger: 'change' }]"
              >
                <el-checkbox
                  :label="(row.is_disaster_day_discount ? '' : '不') + `扣除應出勤時數`"
                  :value="Boolean(row.is_disaster_day_discount)"
                  @change="(val) => (row.is_disaster_day_discount = +val)"
                />
              </el-form-item>
              <el-form-item
                v-if="row.is_flextime"
                class="col-md-6 col-lg-4"
                label="彈性時間"
                prop="flextime.minutes"
                :rules="[{ required: row.is_flextime, message: '請選擇彈性時間', trigger: 'change' }]"
              >
                <el-select v-model="row.flextime.minutes" placeholder="- 請選擇 -">
                  <el-option
                    v-for="(item, index) in [15, 30, 45, 60, 90, 120]"
                    :key="index"
                    :label="item + ' 分鐘'"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item
                v-if="row.type == 0"
                class="col-md-6 col-lg-4"
                label="多段班次"
                prop="is_serial"
                :rules="[{ required: row.type == 0, message: '請選擇是為多段班次', trigger: 'change' }]"
              >
                <el-switch
                  v-model="row.is_serial"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item
                v-if="row.type == 0"
                class="col-md-6 col-lg-4"
                label="可休國定假日"
                prop="is_rest_holiday"
                :rules="[{ required: row.type == 0, message: '請選擇是否可休國定假日', trigger: 'change' }]"
              >
                <el-switch
                  v-model="row.is_rest_holiday"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="有"
                  inactive-text="無"
                />
              </el-form-item>
              <el-form-item
                v-if="row.type == 0"
                class="col-md-6 col-lg-4"
                label="排休天數"
                prop="days_off_per_month"
                :rules="[{ required: row.type == 0, message: '請輸入排休天數', trigger: 'blur' }]"
              >
                <el-input
                  v-model.number="row.days_off_per_month"
                  type="number"
                  :min="1"
                  :max="30"
                  placeholder="排休天數"
                >
                  <template #append>天</template>
                </el-input>
              </el-form-item>
              <el-divider content-position="left">
                上班時段設定
                <b-btn v-if="row.is_serial" variant="success" size="sm" @click.prevent="onAddPeriod">
                  <fa icon="plus" fixed-width />新增
                </b-btn>
              </el-divider>
              <div v-for="(period, serial) in row.period" :key="`period-${serial}`" class="card w-100 mb-1">
                <div class="card-body w-100">
                  <div class="row">
                    <el-form-item
                      class="col-md-6 col-lg-4"
                      label="上班時間"
                      :prop="`period[${serial}].start_at`"
                      :rules="[{ required: true, message: '請輸入上班時間', trigger: 'blur' }]"
                    >
                      <el-time-select
                        ref="t1"
                        v-model="period.start_at"
                        value-format="HH:mm"
                        :picker-options="pickerOption(period.is_over_night, { maxTime: period.end_at })"
                        placeholder="上班時間"
                      ></el-time-select>
                    </el-form-item>
                    <el-form-item
                      class="col-md-6 col-lg-4"
                      label="下班時間"
                      :prop="`period[${serial}].end_at`"
                      :rules="[{ required: true, message: '請輸入下班時間', trigger: 'blur' }]"
                    >
                      <el-time-select
                        ref="t2"
                        v-model="period.end_at"
                        value-format="HH:mm"
                        :picker-options="pickerOption(period.is_over_night, { minTime: period.start_at })"
                        placeholder="下班時間"
                        @change="(val) => (period.overtime_rest_start_at = val)"
                      ></el-time-select>
                    </el-form-item>
                    <el-form-item
                      v-if="row.is_serial"
                      class="col-md-6 col-lg-4"
                      label="隔夜班次"
                      :prop="`period[${serial}].is_over_night`"
                      :rules="[{ required: row.is_serial, message: '請選擇是否為隔夜班次', trigger: 'change' }]"
                    >
                      <el-switch
                        v-model="period.is_over_night"
                        :active-value="1"
                        :inactive-value="0"
                        active-text="是"
                        inactive-text="否"
                      />
                    </el-form-item>
                    <div class="w-100"></div>
                    <el-form-item
                      class="col-md-6 col-lg-4"
                      label="上班休息開始"
                      :prop="`period[${serial}].rest_start_at`"
                      :rules="[{ required: true, message: '請輸入上班休息開始時間', trigger: 'blur' }]"
                    >
                      <el-time-select
                        ref="t3"
                        v-model="period.rest_start_at"
                        value-format="HH:mm"
                        :picker-options="pickerOption(period.is_over_night, { maxTime: period.rest_end_at })"
                        placeholder="上班休息開始"
                      ></el-time-select>
                    </el-form-item>
                    <el-form-item
                      class="col-md-6 col-lg-4"
                      label="上班休息結束"
                      :prop="`period[${serial}].rest_end_at`"
                      :rules="[{ required: true, message: '請輸入上班休息結束時間', trigger: 'blur' }]"
                    >
                      <el-time-select
                        ref="t4"
                        v-model="period.rest_end_at"
                        value-format="HH:mm"
                        :picker-options="pickerOption(period.is_over_night, { minTime: period.rest_start_at })"
                        placeholder="上班休息結束"
                      ></el-time-select>
                    </el-form-item>
                    <el-form-item
                      v-if="row.is_serial"
                      class="col-md-6 col-lg-4"
                      label="班次名稱"
                      :prop="`period[${serial}].name`"
                      :rules="[{ required: row.is_serial, message: '請輸入班次名稱', trigger: 'blur' }]"
                    >
                      <el-input v-model.trim="period.name" placeholder="班次名稱" />
                    </el-form-item>
                    <div class="w-100"></div>
                    <el-form-item
                      v-if="row.is_overtime"
                      class="col-md-6 col-lg-4"
                      label="加班休息開始"
                      :prop="`period[${serial}].overtime_rest_start_at`"
                      :rules="[{ required: row.is_overtime, message: '請選擇加班休息開始時間', trigger: 'change' }]"
                    >
                      <el-time-select
                        ref="t5"
                        v-model="period.overtime_rest_start_at"
                        value-format="HH:mm"
                        :picker-options="pickerOption(period.is_over_night, { maxTime: period.overtime_rest_end_at })"
                        placeholder="加班休息開始"
                      ></el-time-select>
                    </el-form-item>
                    <el-form-item
                      v-if="row.is_overtime"
                      class="col-md-6 col-lg-4"
                      label="加班休息結束"
                      :prop="`period[${serial}].overtime_rest_end_at`"
                      :rules="[{ required: row.is_overtime, message: '請選擇加班休息結束時間', trigger: 'change' }]"
                    >
                      <el-time-select
                        ref="t6"
                        v-model="period.overtime_rest_end_at"
                        value-format="HH:mm"
                        :picker-options="pickerOption(period.is_over_night, { minTime: period.overtime_rest_start_at })"
                        placeholder="加班休息結束"
                      ></el-time-select>
                    </el-form-item>
                    <el-form-item class="col-md-6 col-lg-4">
                      <b-btn v-if="row.is_serial" variant="danger" @click.prevent="onRemovePeriod(serial)">
                        <fa icon="trash" fixed-width />刪除
                      </b-btn>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <el-divider v-if="row.is_annual_leave" content-position="left">特休假設定</el-divider>
              <el-form-item
                v-if="row.is_annual_leave"
                class="col-md-6 col-lg-4"
                label="特休最小單位"
                prop="annual_leave.min_hours"
                :rules="[{ required: row.is_annual_leave, message: '請選擇特休最小單位', trigger: 'change' }]"
              >
                <el-select v-model="row.annual_leave.min_hours" placeholder="- 請選擇 -">
                  <el-option v-for="(item, index) in 8" :key="index" :label="item + ' 小時'" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="row.is_annual_leave"
                class="col-md-6 col-lg-4"
                label="一般特休申請"
                prop="annual_leave.apply_before"
                :rules="[{ required: row.is_annual_leave, message: '請輸入需於幾天前申請', trigger: 'blur' }]"
              >
                <el-input
                  v-model.number="row.annual_leave.apply_before"
                  type="number"
                  :min="1"
                  :max="30"
                  placeholder="幾天前"
                >
                  <template #prepend> 需 </template>
                  <template #append> 天前 </template>
                </el-input>
              </el-form-item>
              <el-form-item
                v-if="row.is_annual_leave"
                class="col-md-6 col-lg-4"
                label="連續休假"
                prop="annual_leave.is_consecutive"
                :rules="[{ required: row.is_annual_leave, message: '請選擇', trigger: 'blur' }]"
              >
                <el-checkbox
                  :label="(row.annual_leave.is_consecutive ? '' : '不') + `連同補、排、例、休假日`"
                  :value="Boolean(row.annual_leave.is_consecutive)"
                  @change="(val) => (row.annual_leave.is_consecutive = +val)"
                />
              </el-form-item>
              <el-form-item
                v-if="row.is_annual_leave"
                class="col-md-6 col-lg-4"
                :label="`${isConsecutive}天數超過`"
                prop="annual_leave.more_than_days"
                :rules="[
                  { required: row.is_annual_leave, message: `請輸入${isConsecutive}超過天數`, trigger: 'blur' },
                  { type: 'number', min: 2, max: 30, message: '請確認天數', trigger: 'change' },
                ]"
              >
                <el-input
                  v-model.number="row.annual_leave.more_than_days"
                  type="number"
                  :min="2"
                  :max="30"
                  :placeholder="`${isConsecutive}超過天數`"
                >
                  <template #append> 天以上 </template>
                </el-input>
              </el-form-item>
              <el-form-item
                v-if="row.is_annual_leave"
                class="col-md-6 col-lg-4"
                :label="`${row.annual_leave.more_than_days}日以上${isConsecutive}`"
                prop="annual_leave.more_than_days_apply_before"
                :rules="[{ required: row.is_annual_leave, message: '請輸入需於幾天前申請', trigger: 'blur' }]"
              >
                <el-input
                  v-model.number="row.annual_leave.more_than_days_apply_before"
                  type="number"
                  :min="1"
                  :max="30"
                  placeholder="幾天前"
                >
                  <template #prepend> 需 </template>
                  <template #append> 天前 </template>
                </el-input>
              </el-form-item>
              <el-divider v-if="row.is_overtime" content-position="left">加班費設定</el-divider>
              <el-form-item
                v-if="row.is_overtime"
                class="col-md-6 col-lg-4"
                label="加班最小單位"
                prop="overtime_min_minutes"
                :rules="[{ required: row.is_overtime, message: '請選擇加班最小單位', trigger: 'change' }]"
              >
                <el-select v-model="row.overtime_min_minutes" placeholder="- 請選擇 -">
                  <el-option
                    v-for="(item, index) in [1, 5, 10, 15, 30, 60]"
                    :key="index"
                    :label="item + ' 分鐘'"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div v-if="row.is_overtime" class="el-form-item col-12">
                <label class="el-form-item__label" style="width: 120px">平日</label>
                <div class="el-form-item__content" style="margin-left: 120px">
                  <ul class="mb-0" style="line-height: 1.5; font-size: 1rem; font-weight: 500">
                    <li>
                      <el-checkbox
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.workday.is_overtime)"
                        @change="(val) => (row.workday.is_overtime = +val)"
                      >
                        {{ row.workday.is_overtime ? '' : '沒' }}有加班費。
                      </el-checkbox>
                    </li>
                    <li>
                      加班時間在
                      <el-button type="text" @click="open('workday.period[1]', '平日加班費')">
                        {{ row.workday.period[1] }}
                      </el-button>
                      小時以內者，按平日每小時工資 x
                      <el-button type="text" @click="open('workday.multiple[0]', '平日加班費')">
                        {{ row.workday.multiple[0] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班時間超過
                      <el-button type="text" @click="open('workday.period[1]', '平日加班費')">
                        {{ row.workday.period[1] }}
                      </el-button>
                      小時未滿
                      <el-button type="text" @click="open('workday.period[2]', '平日加班費')">
                        {{ row.workday.period[2] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('workday.multiple[1]', '平日加班費')">
                        {{ row.workday.multiple[1] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班時間超過
                      <el-button type="text" @click="open('workday.period[2]', '平日加班費')">
                        {{ row.workday.period[2] }}
                      </el-button>
                      小時後者，按平日每小時工資 x
                      <el-button type="text" @click="open('workday.multiple[2]', '平日加班費')">
                        {{ row.workday.multiple[2] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      <el-checkbox
                        v-show="row.type == 2"
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.workday.is_rest)"
                        @change="(val) => (row.workday.is_rest = +val)"
                      >
                        {{ row.workday.is_rest ? '' : '不' }}扣除「上班休息」時間 {{ restHours }} 小時。
                      </el-checkbox>
                      <el-checkbox
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.workday.is_overtime_rest)"
                        @change="(val) => (row.workday.is_overtime_rest = +val)"
                      >
                        {{ row.workday.is_overtime_rest ? '' : '不' }}扣除「加班休息」時間
                        {{ overtimeRestHours }} 小時。
                      </el-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="row.is_overtime" class="el-form-item col-12">
                <label class="el-form-item__label" style="width: 120px">休息日</label>
                <div class="el-form-item__content" style="margin-left: 120px">
                  <ul class="mb-0" style="line-height: 1.5; font-size: 1rem; font-weight: 500">
                    <li>
                      <el-checkbox
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.restday.is_ceil_hours)"
                        @change="(val) => (row.restday.is_ceil_hours = +val)"
                      >
                        {{ row.restday.is_ceil_hours ? '' : '沒' }}有「做 1 得 8 」小時計算。
                      </el-checkbox>
                      <el-checkbox
                        class="m-0 ml-3"
                        style="line-height: 2.5"
                        :value="Boolean(row.restday.is_compensatory)"
                        @change="(val) => (row.restday.is_compensatory = +val)"
                      >
                        {{ row.restday.is_compensatory ? '' : '沒' }}有額外再加一天補休。
                      </el-checkbox>
                    </li>
                    <li>
                      加班時間在
                      <el-button type="text" @click="open('restday.period[1]', '休息日加班費')">
                        {{ row.restday.period[1] }}
                      </el-button>
                      小時以內者，按平日每小時工資 x
                      <el-button type="text" @click="open('restday.multiple[0]', '休息日加班費')">
                        {{ row.restday.multiple[0] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('restday.period[1]', '休息日加班費')">
                        {{ row.restday.period[1] }}
                      </el-button>
                      小時後不超過
                      <el-button type="text" @click="open('restday.period[2]', '休息日加班費')">
                        {{ row.restday.period[2] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('restday.multiple[1]', '休息日加班費')">
                        {{ row.restday.multiple[1] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('restday.period[2]', '休息日加班費')">
                        {{ row.restday.period[2] }}
                      </el-button>
                      小時後不超過
                      <el-button type="text" @click="open('restday.period[3]', '休息日加班費')">
                        {{ row.restday.period[3] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('restday.multiple[2]', '休息日加班費')">
                        {{ row.restday.multiple[2] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('restday.period[3]', '休息日加班費')">
                        {{ row.restday.period[3] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('restday.multiple[3]', '休息日加班費')">
                        {{ row.restday.multiple[3] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      <el-checkbox
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.restday.is_rest)"
                        @change="(val) => (row.restday.is_rest = +val)"
                      >
                        {{ row.restday.is_rest ? '' : '不' }}扣除「上班休息」時間 {{ restHours }} 小時。
                      </el-checkbox>
                      <el-checkbox
                        class="m-0 ml-3"
                        style="line-height: 2.5"
                        :value="Boolean(row.restday.is_overtime_rest)"
                        @change="(val) => (row.restday.is_overtime_rest = +val)"
                      >
                        {{ row.restday.is_overtime_rest ? '' : '不' }}扣除「加班休息」時間
                        {{ overtimeRestHours }} 小時。
                      </el-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="row.is_overtime" class="el-form-item col-12">
                <label class="el-form-item__label" style="width: 120px">國定假日及特別休假</label>
                <div class="el-form-item__content" style="margin-left: 120px">
                  <ul style="line-height: 1.5; font-size: 1rem; font-weight: 500">
                    <li>
                      <el-checkbox
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.holiday.is_ceil_hours)"
                        @change="(val) => (row.holiday.is_ceil_hours = +val)"
                      >
                        {{ row.holiday.is_ceil_hours ? '' : '沒' }}有「做 1 得 8 」小時計算。
                      </el-checkbox>
                      <el-checkbox
                        class="m-0 ml-3"
                        style="line-height: 2.5"
                        :value="Boolean(row.holiday.is_compensatory)"
                        @change="(val) => (row.holiday.is_compensatory = +val)"
                      >
                        {{ row.holiday.is_compensatory ? '' : '沒' }}有額外再加一天補休。
                      </el-checkbox>
                    </li>
                    <li>
                      加班時間在
                      <el-button type="text" @click="open('holiday.period[1]', '休假日加班費')">
                        {{ row.holiday.period[1] }}
                      </el-button>
                      小時以內者，按平日每小時工資 x
                      <el-button type="text" @click="open('holiday.multiple[0]', '休假日加班費')">
                        {{ row.holiday.multiple[0] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('holiday.period[1]', '休假日加班費')">
                        {{ row.holiday.period[1] }}
                      </el-button>
                      小時後不超過
                      <el-button type="text" @click="open('holiday.period[2]', '休假日加班費')">
                        {{ row.holiday.period[2] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('holiday.multiple[1]', '休假日加班費')">
                        {{ row.holiday.multiple[1] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('holiday.period[2]', '休假日加班費')">
                        {{ row.holiday.period[2] }}
                      </el-button>
                      小時後不超過
                      <el-button type="text" @click="open('holiday.period[3]', '休假日加班費')">
                        {{ row.holiday.period[3] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('holiday.multiple[2]', '休假日加班費')">
                        {{ row.holiday.multiple[2] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('holiday.period[3]', '休假日加班費')">
                        {{ row.holiday.period[3] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('holiday.multiple[3]', '休假日加班費')">
                        {{ row.holiday.multiple[3] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      <el-checkbox
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.holiday.is_rest)"
                        @change="(val) => (row.holiday.is_rest = +val)"
                      >
                        {{ row.holiday.is_rest ? '' : '不' }}扣除「上班休息」時間 {{ restHours }} 小時。
                      </el-checkbox>
                      <el-checkbox
                        class="m-0 ml-3"
                        style="line-height: 2.5"
                        :value="Boolean(row.holiday.is_overtime_rest)"
                        @change="(val) => (row.holiday.is_overtime_rest = +val)"
                      >
                        {{ row.holiday.is_overtime_rest ? '' : '不' }}扣除「加班休息」時間
                        {{ overtimeRestHours }} 小時。
                      </el-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="row.is_overtime" class="el-form-item col-12">
                <label class="el-form-item__label" style="width: 120px">例假日</label>
                <div class="el-form-item__content" style="margin-left: 120px">
                  <ul style="line-height: 1.5; font-size: 1rem; font-weight: 500">
                    <li>
                      <el-checkbox
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.regular_day.is_ceil_hours)"
                        @change="(val) => (row.regular_day.is_ceil_hours = +val)"
                      >
                        {{ row.regular_day.is_ceil_hours ? '' : '沒' }}有「做 1 得 8 」小時計算。
                      </el-checkbox>
                      <el-checkbox
                        class="m-0 ml-3"
                        style="line-height: 2.5"
                        :value="Boolean(row.regular_day.is_compensatory)"
                        @change="(val) => (row.regular_day.is_compensatory = +val)"
                      >
                        {{ row.regular_day.is_compensatory ? '' : '沒' }}有額外再加一天補休。
                      </el-checkbox>
                    </li>
                    <li>
                      加班時間在
                      <el-button type="text" @click="open('regular_day.period[1]', '例假日加班費')">
                        {{ row.regular_day.period[1] }}
                      </el-button>
                      小時以內者，按平日每小時工資 x
                      <el-button type="text" @click="open('regular_day.multiple[0]', '例假日加班費')">
                        {{ row.regular_day.multiple[0] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('regular_day.period[1]', '例假日加班費')">
                        {{ row.regular_day.period[1] }}
                      </el-button>
                      小時後不超過
                      <el-button type="text" @click="open('regular_day.period[2]', '例假日加班費')">
                        {{ row.regular_day.period[2] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('regular_day.multiple[1]', '例假日加班費')">
                        {{ row.regular_day.multiple[1] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('regular_day.period[2]', '例假日加班費')">
                        {{ row.regular_day.period[2] }}
                      </el-button>
                      小時後不超過
                      <el-button type="text" @click="open('regular_day.period[3]', '例假日加班費')">
                        {{ row.regular_day.period[3] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('regular_day.multiple[2]', '例假日加班費')">
                        {{ row.regular_day.multiple[2] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      加班超過
                      <el-button type="text" @click="open('regular_day.period[3]', '例假日加班費')">
                        {{ row.regular_day.period[3] }}
                      </el-button>
                      小時者，按平日每小時工資 x
                      <el-button type="text" @click="open('regular_day.multiple[3]', '例假日加班費')">
                        {{ row.regular_day.multiple[3] }}
                      </el-button>
                      倍。
                    </li>
                    <li>
                      <el-checkbox
                        style="line-height: 2.5; margin: 0"
                        :value="Boolean(row.regular_day.is_rest)"
                        @change="(val) => (row.regular_day.is_rest = +val)"
                      >
                        {{ row.regular_day.is_rest ? '' : '不' }}扣除「上班休息」時間 {{ restHours }} 小時。
                      </el-checkbox>
                      <el-checkbox
                        class="m-0 ml-3"
                        style="line-height: 2.5"
                        :value="Boolean(row.regular_day.is_overtime_rest)"
                        @change="(val) => (row.regular_day.is_overtime_rest = +val)"
                      >
                        {{ row.regular_day.is_overtime_rest ? '' : '不' }}扣除「加班休息」時間
                        {{ overtimeRestHours }} 小時。
                      </el-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-row">
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model="row.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/difference_in_minutes'
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { shiftTypes, shiftGrandTotal } from '@/plugins/const'
import { set } from 'lodash-es'

export default {
  name: 'ShiftSetting',
  mixins: [modify],
  data() {
    return {
      row: {
        id: null,
        name: null,
        is_audit: 1,
        type: 1,
        is_flextime: 0,
        is_annual_leave: 1,
        is_overtime: 1,
        overtime_min_minutes: 30,
        days_off_per_month: 8,
        is_grand_total: 1,
        is_rest_holiday: 1,
        is_disaster_day_discount: 1,
        basic_hours: 8,
        is_serial: 0,
        period: [
          {
            name: null,
            is_over_night: 0,
            end_at: '18:00',
            start_at: '08:30',
            rest_end_at: '13:30',
            rest_start_at: '12:00',
            overtime_rest_end_at: '18:30',
            overtime_rest_start_at: '18:00',
          },
        ],
        flextime: {
          minutes: 30,
        },
        annual_leave: {
          min_hours: 2,
          apply_before: 3,
          is_consecutive: 1,
          more_than_days: 3,
          more_than_days_apply_before: 7,
        },
        workday: {
          period: [0, 2, 4],
          multiple: [1.34, 1.67, 1.67],
          is_rest: 1,
          is_overtime: 1,
          is_overtime_rest: 1,
        },
        restday: {
          period: [0, 2, 8, 12],
          multiple: [1.34, 1.67, 2.67, 2.67],
          is_ceil_hours: 0,
          is_compensatory: 0,
          is_rest: 1,
          is_overtime_rest: 1,
        },
        regular_day: {
          period: [0, 8, 10, 12],
          multiple: [1, 2, 2, 2],
          is_ceil_hours: 0,
          is_compensatory: 0,
          is_rest: 1,
          is_overtime_rest: 1,
        },
        holiday: {
          period: [0, 8, 10, 12],
          multiple: [1, 1.34, 1.67, 1.67],
          is_ceil_hours: 0,
          is_compensatory: 0,
          is_rest: 1,
          is_overtime_rest: 1,
        },
        comment: null,
      },
      rules: {
        name: [{ required: true, message: '請輸入班別名稱', trigger: 'blur' }],
        is_audit: [{ required: true, message: '請選擇出勤考核', trigger: 'blur' }],
        type: [{ required: true, message: '請選擇休假方式', trigger: 'blur' }],
        is_flextime: [{ required: true, message: '請選擇是否彈性上下班', trigger: 'blur' }],
        'period.start_at': [{ required: true, message: '請輸入上班時間', trigger: 'blur' }],
        'period.end_at': [{ required: true, message: '請輸入下班時間', trigger: 'blur' }],
        'period.rest_start_at': [{ required: true, message: '請輸入上班休息開始時間', trigger: 'blur' }],
        'period.rest_end_at': [{ required: true, message: '請輸入上班休息結束時間', trigger: 'blur' }],
        is_annual_leave: [{ required: true, message: '請選擇特休假', trigger: 'blur' }],
        is_overtime: [{ required: true, message: '請選擇加班費', trigger: 'blur' }],
      },
      now: format(new Date(), 'YYYY-MM-DD'),
      shiftGrandTotal,
      shiftTypes,
    }
  },
  computed: {
    isConsecutive() {
      return this.row.annual_leave.is_consecutive ? '休假' : '特休'
    },
    restHours() {
      let start = parse(this.now + ' ' + this.row.period[0].rest_start_at + ':00')
      let end = parse(this.now + ' ' + this.row.period[0].rest_end_at + ':00')
      let diff = Math.abs(differenceInMinutes(end, start))
      return diff / 60
    },
    overtimeRestHours() {
      let start = parse(this.now + ' ' + this.row.period[0].overtime_rest_start_at + ':00')
      let end = parse(this.now + ' ' + this.row.period[0].overtime_rest_end_at + ':00')
      let diff = Math.abs(differenceInMinutes(end, start))
      return diff / 60
    },
    workHours() {
      let start = parse(this.now + ' ' + this.row.period[0].start_at + ':00')
      let end = parse(this.now + ' ' + this.row.period[0].end_at + ':00')
      let diff = Math.abs(differenceInMinutes(end, start))
      return diff / 60 - this.restHours
    },
    extraRestHours() {
      return this.workHours * 30 - 240
    },
  },
  methods: {
    ...mapActions('shift', ['fetchOne', 'doCreate', 'doUpdate']),
    resetRow() {
      this.$set(this.$data, 'row', {
        id: null,
        name: null,
        is_audit: 1,
        type: 1,
        is_flextime: 0,
        is_annual_leave: 1,
        is_overtime: 1,
        overtime_min_minutes: 30,
        days_off_per_month: 8,
        is_grand_total: 1,
        is_rest_holiday: 1,
        is_disaster_day_discount: 1,
        basic_hours: 8,
        is_serial: 0,
        period: [
          {
            name: null,
            is_over_night: 0,
            end_at: '18:00',
            start_at: '08:30',
            rest_end_at: '13:30',
            rest_start_at: '12:00',
            overtime_rest_end_at: '18:30',
            overtime_rest_start_at: '18:00',
          },
        ],
        flextime: {
          minutes: 30,
        },
        annual_leave: {
          min_hours: 2,
          apply_before: 3,
          is_consecutive: 1,
          more_than_days: 3,
          more_than_days_apply_before: 7,
        },
        workday: {
          period: [0, 2, 4],
          multiple: [1.34, 1.67, 1.67],
          is_rest: 1,
          is_overtime: 1,
          is_overtime_rest: 1,
        },
        restday: {
          period: [0, 2, 8, 12],
          multiple: [1.34, 1.67, 2.67, 2.67],
          is_ceil_hours: 0,
          is_compensatory: 0,
          is_rest: 1,
          is_overtime_rest: 1,
        },
        regular_day: {
          period: [0, 8, 10, 12],
          multiple: [1, 2, 2, 2],
          is_ceil_hours: 0,
          is_compensatory: 0,
          is_rest: 1,
          is_overtime_rest: 1,
        },
        holiday: {
          period: [0, 8, 10, 12],
          multiple: [1, 1.34, 1.67, 1.67],
          is_ceil_hours: 0,
          is_compensatory: 0,
          is_rest: 1,
          is_overtime_rest: 1,
        },
        comment: null,
      })
      this.$forceUpdate()
    },
    pickerOption(val, extra) {
      return val
        ? { start: '00:00', step: '00:30', end: '23:30' }
        : Object.assign({}, { start: '00:00', step: '00:30', end: '23:30' }, extra)
    },
    open(target, message, divide = 1) {
      this.$prompt(`更改${message}變數`, '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        inputPattern: /^[1-9]+[0]?(\.\d+)?$/,
        inputErrorMessage: '輸入內容不正確',
      })
        .then(({ value }) => {
          set(this.row, target, value * divide)
          this.$forceUpdate()
        })
        .catch(() => {})
    },
    modalOpen() {
      // 修正 el-time-select 怪問題
      if (this.$refs.t1) {
        this.$refs.t1.forEach((t1) => {
          t1.userInput = null
          t1.valueOnOpen = null
        })
        this.$refs.t2.forEach((t2) => {
          t2.userInput = null
          t2.valueOnOpen = null
        })
        this.$refs.t3.forEach((t3) => {
          t3.userInput = null
          t3.valueOnOpen = null
        })
        this.$refs.t4.forEach((t4) => {
          t4.userInput = null
          t4.valueOnOpen = null
        })
      }

      if (this.$refs.t5) {
        this.$refs.t5.forEach((t5) => {
          t5.userInput = null
          t5.valueOnOpen = null
        })
        this.$refs.t6.forEach((t6) => {
          t6.userInput = null
          t6.valueOnOpen = null
        })
      }
    },
    onAudit(val) {
      if (!val) {
        this.row.is_overtime = 0
        this.row.is_flextime = 0
      }
    },
    onAnnual(val) {
      this.row.is_grand_total = +val
    },
    onAddPeriod() {
      this.row.period.push({
        name: null,
        is_over_night: 0,
        end_at: '18:00',
        start_at: '08:30',
        rest_end_at: '13:30',
        rest_start_at: '12:00',
        overtime_rest_end_at: '18:30',
        overtime_rest_start_at: '18:00',
      })
    },
    onRemovePeriod(i) {
      this.row.period.splice(i, 1)

      if (this.row.period.length < 1) {
        this.onAddPeriod()
      }
    },
  },
}
</script>
