<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + '請假假別' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="120px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="假別名稱" prop="name">
                <el-input v-model.trim="row.name" placeholder="假別名稱" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="是否啟用" prop="is_enable">
                <el-switch
                  v-model="row.is_enable"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="請假最小單位" prop="min_hours">
                <el-select v-model="row.min_hours" placeholder="- 請選擇 -">
                  <el-option v-for="(item, index) in 8" :key="index" :label="item + ' 小時'" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="可分段申請" prop="is_separate">
                <el-switch
                  v-model="row.is_separate"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="包含假日" prop="is_ignore_holidays">
                <el-checkbox
                  style="line-height: 1.5; margin: 0"
                  :value="Boolean(row.is_ignore_holidays)"
                  @change="(val) => (row.is_ignore_holidays = +val)"
                >
                  {{ row.is_ignore_holidays ? '' : '不' }}包含休息、例假與國定假日
                </el-checkbox>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="有每年限制" prop="is_max">
                <el-switch
                  v-model="row.is_max"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item
                v-if="row.is_max"
                :rules="[
                  {
                    required: row.is_max,
                    type: 'number',
                    min: 1,
                    max: 365,
                    message: '請輸入每年限制天數',
                    trigger: 'change',
                  },
                ]"
                label="每年限制天數"
                prop="max_days_per_year"
                class="col-md-6 col-lg-4"
              >
                <el-input
                  v-model.number="row.max_days_per_year"
                  type="number"
                  :min="1"
                  :max="365"
                  placeholder="每年不得超過天數"
                >
                  <template #append>天內</template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="有請假期限" prop="is_period">
                <el-switch
                  v-model="row.is_period"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item
                v-if="row.is_period"
                :rules="[
                  {
                    required: row.is_period,
                    type: 'number',
                    min: 1,
                    max: 365,
                    message: '請輸入請假期限天數',
                    trigger: 'change',
                  },
                ]"
                label="請假期限"
                prop="period_days"
                class="col-md-6 col-lg-4"
              >
                <el-input
                  v-model.number="row.period_days"
                  type="number"
                  :min="1"
                  :max="365"
                  placeholder="應於 xx 天內請完"
                >
                  <template #append>天內</template>
                </el-input>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="限女性" prop="is_female_only">
                <el-switch
                  v-model="row.is_female_only"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="年資限定" prop="is_seniority">
                <el-switch
                  v-model="row.is_seniority"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item
                v-if="row.is_seniority"
                :rules="[
                  {
                    required: row.is_seniority,
                    type: 'number',
                    min: 1,
                    message: '請輸入年資屆滿月數',
                    trigger: 'change',
                  },
                ]"
                label="年資屆滿月數"
                prop="seniority_month"
                class="col-md-6 col-lg-4"
              >
                <el-input v-model.number="row.seniority_month" type="number" :min="1" placeholder="年資屆滿月數">
                  <template #append>月以上</template>
                </el-input>
              </el-form-item>
              <el-form-item
                v-if="row.is_seniority"
                :rules="[
                  {
                    required: row.is_seniority,
                    type: 'number',
                    min: 0,
                    max: 1,
                    message: '請輸入年資未滿的薪水倍數',
                    trigger: 'change',
                  },
                ]"
                label="年資未滿倍數"
                prop="not_seniority_multiple"
                class="col-md-6 col-lg-4"
              >
                <el-input
                  v-model.number="row.not_seniority_multiple"
                  type="number"
                  :min="0"
                  :max="1"
                  :step="0.1"
                  placeholder="年資未滿倍數"
                >
                  <template #append>倍</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="form-row">
              <div class="el-form-item col-12">
                <label class="el-form-item__label required" style="width: 120px">詳細規定</label>
                <div class="el-form-item__content p-0" style="margin-left: 120px">
                  <table class="table table-bordered table-striped m-0" style="line-height: 1">
                    <thead>
                      <tr>
                        <th style="width: 50px">項次</th>
                        <th>內容</th>
                        <th style="width: 110px">給假天數</th>
                        <th style="width: 110px">薪水倍數</th>
                        <th style="width: 40px">
                          <div class="text-success" style="cursor: pointer">
                            <fa class="fa-lg" icon="plus-circle" fixed-width @click="onAddItem" />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in row.items" :key="index">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="p-0">
                          <el-form-item
                            :rules="[{ required: true, message: ' ', trigger: 'blur' }]"
                            :prop="`items[${index}].title`"
                            label-width="0px"
                            class="m-0 p-0"
                            size="small"
                          >
                            <el-input v-model.trim="item.title" placeholder="內容說明" />
                          </el-form-item>
                        </td>
                        <td class="p-0">
                          <el-form-item
                            :rules="[{ type: 'number', min: 1, max: 365, message: ' ', trigger: 'change' }]"
                            :prop="`items[${index}].days`"
                            label-width="0px"
                            class="m-0 p-0"
                            size="small"
                          >
                            <el-input
                              v-model.number="item.days"
                              type="number"
                              :min="1"
                              :max="365"
                              placeholder="給假天數"
                            >
                              <template #append>天</template>
                            </el-input>
                          </el-form-item>
                        </td>
                        <td class="p-0">
                          <el-form-item
                            :rules="[
                              { required: true, type: 'number', min: 0, max: 1, message: ' ', trigger: 'change' },
                            ]"
                            :prop="`items[${index}].multiple`"
                            label-width="0px"
                            class="m-0 p-0"
                            size="small"
                          >
                            <el-input
                              v-model.number="item.multiple"
                              type="number"
                              :min="0"
                              :max="1"
                              :step="0.1"
                              placeholder="薪水倍數"
                            >
                              <template #append>倍</template>
                            </el-input>
                          </el-form-item>
                        </td>
                        <td>
                          <div class="text-danger" style="cursor: pointer">
                            <fa class="fa-lg" icon="times-circle" fixed-width @click="onRemoveItem(index)" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="form-row">
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model="row.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'LeaveSetting',
  mixins: [modify],
  data() {
    return {
      row: {
        id: null,
        name: null,
        is_enable: 1,
        is_female_only: 0,
        min_hours: 1,
        is_separate: 0,
        is_ignore_holidays: 0,
        is_period: 0,
        period_days: null,
        is_seniority: 0,
        seniority_month: null,
        not_seniority_multiple: null,
        limit_days_per_year: null,
        is_max: 0,
        max_days_per_year: null,
        items: [],
        comment: null,
      },
      rules: {
        name: [{ required: true, message: '請輸入節點名稱', trigger: 'blur' }],
        is_enable: [{ required: true, message: '請選擇是否啟用', trigger: 'change' }],
        is_female_only: [{ required: true, message: '請選擇限女性', trigger: 'change' }],
        min_hours: [{ required: true, message: '請選擇請假最小單位', trigger: 'change' }],
        is_separate: [{ required: true, message: '請選擇可否分段申請', trigger: 'change' }],
        is_ignore_holidays: [{ required: true, message: '請選擇是否包含假日', trigger: 'change' }],
        is_period: [{ required: true, message: '請選擇是否有請假期限', trigger: 'change' }],
        is_seniority: [{ required: true, message: '請選擇是否有年資限定', trigger: 'change' }],
        is_max: [{ required: true, message: '請選擇是否有每年限制', trigger: 'change' }],
      },
    }
  },
  created() {},
  methods: {
    ...mapActions('leave', ['fetchOne', 'doCreate', 'doUpdate']),
    resetRow() {
      this.row = {
        id: null,
        name: null,
        is_enable: 1,
        is_female_only: 0,
        min_hours: 1,
        is_separate: 0,
        is_ignore_holidays: 0,
        is_period: 0,
        period_days: null,
        is_seniority: 0,
        seniority_month: null,
        not_seniority_multiple: null,
        is_max: 0,
        max_days_per_year: null,
        limit_days_per_year: null,
        items: [{ title: null, days: null, multiple: null }],
        comment: null,
      }
    },
    onAddItem() {
      this.row.items.push({ title: null, days: null, multiple: null })
    },
    onRemoveItem(index) {
      this.row.items.splice(index, 1)
      if (this.row.items.length < 1) {
        this.onAddItem()
      }
    },
  },
}
</script>
