<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ (row.department && row.department.name + ' ') + row.name + ' 特休假歷史記錄' }}
        </h3>
      </template>
      <table class="table table-bordered table-striped">
        <thead>
          <tr class="text-center">
            <th>起訖時間</th>
            <th>上年度累計</th>
            <th>本年度時數</th>
            <th>可休總時數</th>
            <th>已休總時數</th>
            <th>未休總時數</th>
            <th style="width: 53px">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="annual in row.annuals" :key="annual.id" class="text-center">
            <td class="text-monospace">
              <span class="text-nowrap">{{ format(annual.created_at, 'YYYY-MM-DD') }}</span> ~
              <span class="text-nowrap">{{ format(annual.deadline, 'YYYY-MM-DD') }}</span>
            </td>
            <td>{{ `${annual.accumulated} 小時` }}</td>
            <td>{{ `${annual.hours} 小時` }}</td>
            <td>{{ `${annual.total} 小時` }}</td>
            <td>{{ `${annual.used_hours} 小時` }}</td>
            <td>{{ `${annual.total - annual.used_hours} 小時` }}</td>
            <td>
              <b-btn
                v-show="annual.is_finish == 0"
                size="sm"
                variant="primary"
                @click.prevent="$refs.annualLeaveSetting.init(annual.id)"
              >
                <fa icon="cog" fixed-width />
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />關閉</b-btn>
        </div>
      </div>
    </el-dialog>
    <annual-leave-setting ref="annualLeaveSetting" @done="onDone" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { format } from 'date-fns'

export default {
  name: 'AnnualLeaveList',
  mixins: [modify],
  data() {
    return {
      row: {},
      modalMaxWidth: 900,
    }
  },
  methods: {
    ...mapActions('annualLeave', { fetchOne: 'fetchList' }),
    onDone() {
      this.onUpdate(this.row.id)
      this.$emit('done')
    },
    format,
  },
}
</script>
