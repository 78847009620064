<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + '員工資料' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="120px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6 col-lg-4" label="中文姓名" prop="name">
                <el-input v-model.trim="row.name" placeholder="中文姓名" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="英文姓名" prop="eng_name">
                <el-input v-model.trim="row.eng_name" placeholder="英文姓名" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="性別" prop="sex">
                <el-radio-group v-model="row.sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="部門" prop="department_id">
                <el-select v-model.number="row.department_id" placeholder="- 請選擇 -">
                  <el-option
                    v-for="item in $store.getters['department/all']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="職稱" prop="title">
                <el-input v-model.trim="row.title" placeholder="職稱" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="在職狀況" prop="status">
                <el-select v-model.number="row.status" placeholder="- 請選擇 -" @change="onStatusChange">
                  <el-option v-for="(item, index) in userStatus" :key="index" :label="item" :value="Number(index)" />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="班別" prop="shift_id">
                <el-select v-model.number="row.shift_id" placeholder="- 請選擇 -">
                  <el-option
                    v-for="item in $store.getters['shift/all']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="感應卡號"
                prop="card_id"
                :rules="[
                  { required: isRequired, message: '請輸入感應卡號', trigger: 'blur' },
                  { validator: doCheckUniqueCard, message: '感應卡號重複，請重新確認', trigger: 'blur' },
                ]"
              >
                <el-input
                  v-model.trim="row.card_id"
                  v-mask="'#####:#####'"
                  placeholder="感應卡號，如：12345:54321，不打卡請留空白"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="後臺管理" prop="is_admin">
                <el-switch
                  v-model="row.is_admin"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="身份證號" prop="id_no">
                <el-input v-model.trim="row.id_no" v-mask="'A#########'" placeholder="身份證號" />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="行動電話" prop="mobile">
                <el-input v-model.trim="row.mobile" v-mask="'####-######'" placeholder="行動電話，如：0932-123456" />
              </el-form-item>
              <el-form-item
                :rules="[
                  { required: formAction === 'create', message: '請輸入登入密碼', trigger: 'blur' },
                  { type: 'string', min: 6, message: '密碼長度最少為 6 個字元', trigger: 'change' },
                ]"
                class="col-md-6 col-lg-4"
                label="登入密碼"
                prop="password"
              >
                <el-input
                  v-model.trim="row.password"
                  :placeholder="formAction !== 'create' ? '如不更改密碼，請保留空白' : '登入密碼'"
                  type="password"
                  auto-complete="new-password"
                />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6 col-lg-4" label="出生日期" prop="birthday">
                <el-date-picker
                  v-model="row.birthday"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="出生日期"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="到職日期" prop="start_at">
                <el-date-picker
                  v-model="row.start_at"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="到職日期"
                />
              </el-form-item>
              <el-form-item class="col-md-6 col-lg-4" label="年資起算日期" prop="seniority_at">
                <el-date-picker
                  v-model="row.seniority_at"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="年資起算日期"
                  disabled
                />
              </el-form-item>
              <el-form-item
                v-if="shift && shift.is_serial"
                class="col-md-6 col-lg-4"
                label="預設班次"
                prop="default_serial"
                :rules="[{ required: shift && shift.is_serial, message: '請選擇預設班次', trigger: 'change' }]"
              >
                <el-select v-model.number="row.default_serial" placeholder="- 請選擇 -">
                  <el-option v-for="(item, index) in shift.period" :key="index" :label="item.name" :value="index" />
                </el-select>
              </el-form-item>
              <el-form-item
                class="col-md-6 col-lg-4"
                label="離職日期"
                :rules="[{ required: row.status === 3, message: '請輸入離職日期', trigger: 'change' }]"
                prop="end_at"
              >
                <el-date-picker
                  v-model="row.end_at"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="離職日期"
                  @change="onEndAt"
                />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-12" label="聯絡地址" prop="address">
                <el-input v-model.trim="row.address" placeholder="聯絡地址" />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-12" label="Email" prop="email">
                <el-input v-model.trim="row.email" type="email" placeholder="Email" auto-complete="email" />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-12" label="緊急聯絡人" prop="emergency_contact">
                <el-input
                  v-model="row.emergency_contact"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="緊急聯絡人"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { userStatus } from '@/plugins/const'
import { find } from 'lodash-es'

export default {
  name: 'UserSetting',
  mixins: [modify],
  data() {
    return {
      row: {
        id: null,
        name: null,
        eng_name: null,
        sex: null,
        department_id: null,
        status: 1,
        title: null,
        is_admin: 0,
        shift_id: null,
        default_serial: 0,
        card_id: null,
        id_no: null,
        mobile: null,
        password: null,
        birthday: null,
        start_at: null,
        end_at: null,
        address: null,
        email: null,
        emergency_contact: null,
      },
      rules: {
        name: [{ required: true, message: '請輸入中文姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '請選擇性別', trigger: 'change' }],
        title: [{ required: true, message: '請輸入職稱', trigger: 'change' }],
        is_admin: [{ required: true, message: '請選擇是否可以後臺管理', trigger: 'change' }],
        shift_id: [{ required: true, message: '請選擇權限等級', trigger: 'change' }],
        department_id: [{ required: true, message: '請選擇部門', trigger: 'change' }],
        status: [{ required: true, message: '請選擇在職狀況', trigger: 'change' }],
        // card_id: ,
        id_no: [{ required: true, message: '請輸入身份證號', trigger: 'change' }],
        mobile: [
          { required: true, message: '請輸入行動電話', trigger: 'blur' },
          { validator: this.doCheckUniqueMobile, message: '行動電話重複，請重新確認', trigger: 'blur' },
        ],
        birthday: [{ required: true, message: '請選擇出生日期', trigger: 'blur' }],
        start_at: [{ required: true, message: '請選擇到職日期', trigger: 'blur' }],
        address: [{ required: true, message: '請輸入聯絡地址', trigger: 'blur' }],
        email: [{ type: 'email', message: '請確認Email', trigger: 'change' }],
      },
      userStatus,
    }
  },
  computed: {
    isRequired() {
      if (this.row.status == 3) {
        return false
      }
      return this.shift && this.shift.is_audit == 1
    },
    shift() {
      return find(this.$store.getters['shift/all'], { id: +this.row.shift_id })
    },
  },
  watch: {
    shift: {
      handler() {
        if (this.shift?.is_serial == 0) {
          this.row.default_serial = 0
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('user', ['fetchOne', 'doCreate', 'doUpdate', 'checkUniqueMobile', 'checkUniqueCard']),
    resetRow() {
      this.row = {
        id: null,
        name: null,
        eng_name: null,
        sex: null,
        department_id: null,
        status: 1,
        title: null,
        is_admin: 0,
        shift_id: null,
        default_serial: 0,
        card_id: null,
        id_no: null,
        mobile: null,
        password: null,
        birthday: null,
        start_at: null,
        end_at: null,
        address: null,
        email: null,
        emergency_contact: null,
      }
    },
    async doCheckUniqueMobile(rule, value, callback) {
      if (this.formAction === 'update') {
        callback()
      } else {
        const result = await this.checkUniqueMobile(value)
        if (result) {
          let error = [rule.field + ' already taken']
          callback(error)
        }
        callback()
      }
    },
    async doCheckUniqueCard(rule, value, callback) {
      if (this.formAction === 'update') {
        callback()
      } else {
        const result = await this.checkUniqueCard(value)
        if (result) {
          let error = [rule.field + ' already taken']
          callback(error)
        }
        callback()
      }
    },
    onStatusChange(val) {
      if (val !== 3) {
        this.row.end_at = null
      } else {
        this.row.card_id = null
      }
    },
    onEndAt(val) {
      this.row.status = val ? 3 : 1
    },
  },
}
</script>
