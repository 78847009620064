import Vue from 'vue'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://947c64449b7c4867be83db5517708bdd@sentry.io/1809312',
    ignoreErrors: ['ResizeObserver', 'FirebaseError'],
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  })
}
