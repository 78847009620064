<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ actionText + '公告' }}
      </h3>
    </template>
    <el-form :ref="formRefs" :model="row" :rules="rules" label-width="100px">
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6" label="公告部門" prop="from_id">
              <el-select v-model.number="row.from_id" placeholder="請選擇">
                <el-option
                  v-for="item in $store.getters['department/all']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-md-6" label="狀態">
              <el-switch
                v-model.number="row.is_publish"
                :active-value="1"
                :inactive-value="0"
                active-text="上架"
                inactive-text="下架"
              />
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-12" label="公告對象" prop="targets">
              <el-select v-model="row.targets" placeholder="請選擇" multiple>
                <el-option
                  v-for="item in $store.getters['department/all']"
                  :key="item.id"
                  :label="item.name"
                  :value="Number(item.id)"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="col-12" label="公告主題" prop="title">
              <el-input v-model="row.title" placeholder="公告主題" />
            </el-form-item>
            <el-form-item class="col-12" label="公告內容" prop="content">
              <el-input v-model="row.content" type="textarea" :rows="6" placeholder="公告內容" />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
          <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'BulletinBoardSetting',
  mixins: [modify],
  data() {
    return {
      row: {
        id: null,
        is_publish: 0,
        from_id: null,
        targets: [],
        title: null,
        content: null,
      },
      rules: {
        from_id: [{ required: true, message: '請選擇公告部門', trigger: 'change' }],
        targets: [{ required: true, message: '請選擇公告對象', trigger: 'change' }],
        title: [{ required: true, message: '請輸入公告主題', trigger: 'blur' }],
        content: [{ required: true, message: '請輸入公告內容', trigger: 'blur' }],
      },
      modalMaxWidth: 1000,
    }
  },
  created() {},
  methods: {
    ...mapActions('bulletinBoard', ['fetchOne', 'doCreate', 'doUpdate']),
    resetRow() {
      this.row = {
        id: null,
        is_publish: 0,
        from_id: null,
        targets: [],
        title: null,
        content: null,
      }
    },
  },
}
</script>
