<template>
  <div :class="classObj">
    <div v-if="device === 'mobile' && sidebar" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="d-flex flex-column main-container">
      <navbar class="order-first" />
      <app-main class="order-2" />
      <footer class="order-last footer">
        <strong class="float-right mr-3">&copy; {{ new Date().getFullYear() }} 磐鈺建設</strong>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Basic',
  computed: {
    ...mapState('app', ['sidebar', 'device']),
    classObj() {
      return {
        'hide-sidebar': !this.sidebar,
        'open-sidebar': this.sidebar,
        mobile: this.device === 'mobile',
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.handleCacheView()
      },
      immediate: true,
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  methods: {
    ...mapActions('app', ['updateWindowWidth']),
    handleClickOutside() {
      this.$store.dispatch('app/closeSidebar')
    },
    handleCacheView() {
      if (this.$route.meta.cache) {
        this.$store.dispatch('app/addCachedView', this.$route)
      }
    },
  },
}
</script>
