<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + '特休假' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6" label="到職滿" prop="month">
                <h6>
                  {{ row.month > 10 ? row.month / 12 + ' 年' + (row.month >= 300 ? '以上' : '') : `${row.month} 月` }}
                </h6>
              </el-form-item>
              <el-form-item class="col-md-6" label="給假天數" prop="days">
                <el-input v-model.number="row.days" type="number" :min="1" placeholder="給假天數">
                  <template #append>天</template>
                </el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'AnnualSetting',
  mixins: [modify],
  data() {
    return {
      row: {
        id: null,
        month: null,
        days: null,
      },
      rules: {
        days: [{ required: true, type: 'number', min: 1, message: '請輸入天數', trigger: 'blur' }],
      },
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('annual', ['fetchOne', 'doCreate', 'doUpdate']),
  },
}
</script>
