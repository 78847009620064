import axios from '@/plugins/axios'

const url = 'leave-apply-audits'
const leaveApplyAudit = {
  namespaced: true,
  actions: {
    async fetchMyAudit({}, params) {
      let { data } = await axios.get(`/${url}/me`, { params })
      return data
    },
    async doApprove({}, { id, comment }) {
      let { data } = await axios.put(`/${url}/${id}`, { is_approved: 1, comment: comment })
      return data
    },
    async doReject({}, { id, comment }) {
      let { data } = await axios.put(`/${url}/${id}`, { is_approved: 0, comment: comment })
      return data
    },
  },
}

export default leaveApplyAudit
