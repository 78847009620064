<template>
  <div>
    <el-dialog :visible.sync="modalShow" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ row.department.name + ' ' + row.name + ' (' + row.shift.name + ')' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="form-row">
          <h5 class="col-8">
            可排天數：{{ row.days_off + holidays.length }} 天（例假日：{{ regularDays }}，休息日：{{
              restDays
            }}，休假日： {{ row.shift.is_rest_holiday ? holidays.length : 0 }}）
          </h5>
          <h5 class="col">已排天數：{{ arrangesCount }} 天</h5>
        </div>
        <full-calendar
          ref="fullCalendar"
          :default-date="format($route.params.date, 'YYYY-MM') + '-01'"
          :valid-range="{ start: row.start_at, end: row.end_at }"
          :title-format="({ date }) => format(date.marker, 'YYYY 年 MM 月')"
          :column-header-text="
            (d) => ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][d.getDay()]
          "
          :header="false"
          :plugins="calendarPlugins"
          :events="calendarEvents"
          :show-non-current-dates="false"
          default-view="dayGridMonth"
          theme-system="bootstrap"
          content-height="auto"
          @dateClick="onDateClick"
        />
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { find, reject } from 'lodash-es'

export default {
  name: 'ArrangeSetting',
  components: { FullCalendar },
  mixins: [modify],
  data() {
    return {
      calendarPlugins: [dayGridPlugin, interactionPlugin, bootstrapPlugin],
      calendarEvents: [],
      holidays: [],
      holidaysRemain: 0,
      restDays: 0,
      restDaysRemain: 0,
      regularDays: 0,
      regularDaysRemain: 0,
      row: {
        id: null,
        shift_id: null,
        department_id: null,
        status: 1,
        name: null,
        start_at: null,
        end_at: null,
        days_off: 0,
        shift: {
          name: null,
          is_rest_holiday: 0,
          days_off_per_month: 0,
        },
        department: {
          name: null,
        },
        arranges: [],
      },
      rules: {},
      modalMaxWidth: 800,
    }
  },
  computed: {
    arrangesCount() {
      return this.row.arranges.length || 0
    },
  },
  watch: {
    calendarEvents() {
      let arranges = []
      this.calendarEvents.forEach((item) => {
        if (item.rendering === 'background') {
          arranges.push({
            id: item.id || null,
            user_id: this.row.id,
            date: item.start,
            type: item.type,
          })
        }
      })
      this.$set(this.row, 'arranges', arranges)
    },
  },
  methods: {
    ...mapActions('arrange', ['fetchMonthlyByUserId', 'doUpdate']),
    onUpdate(id) {
      this.formAction = 'update'
      this.fetchMonthlyByUserId({ date: format(this.$route.params.date, 'YYYY-MM'), id }).then((result) => {
        this.$set(this.$data, 'row', result.user)
        this.$set(this.$data, 'holidays', result.holidays)

        let daysOff = +result.user.days_off
        this.regularDaysRemain = this.regularDays = Math.round(daysOff / 2)
        this.restDaysRemain = this.restDays = daysOff - this.regularDays
        this.holidaysRemain = result.holidays.length

        let holidays = []
        result.holidays.forEach((holiday) => {
          holidays.push({
            title: holiday.name,
            start: holiday.date,
            end: holiday.date,
            allDay: true,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          })
        })
        let arranges = []
        result.user.arranges.forEach((arrange) => {
          arranges.push({
            id: arrange.id,
            // title: '休假',
            type: arrange.type,
            start: arrange.date,
            allDay: true,
            rendering: 'background',
            className: 'bg-danger',
            // backgroundColor: 'red',
            // textColor: 'white'
          })

          switch (arrange.type) {
            case 1:
              this.regularDaysRemain--
              break
            case 2:
              this.restDaysRemain--
              break
            case 3:
              this.holidaysRemain--
              break
          }
        })
        this.calendarEvents = [].concat(holidays).concat(arranges)
        this.resetForm()
        this.$nextTick(() => {
          this.modalShow = true
          if (this.$refs.fullCalendar) {
            this.$nextTick(() => {
              this.$refs.fullCalendar.getApi().gotoDate(format(this.$route.params.date, 'YYYY-MM') + '-01')
            })
          }
        })
      })
    },
    onDateClick(val) {
      let found = find(this.calendarEvents, { start: val.dateStr, rendering: 'background' })
      if (found === undefined && this.arrangesCount >= this.row.days_off + this.holidays.length) {
        this.$message.error('已超過可排休天數')
        return false
      }
      if (found) {
        this.calendarEvents = reject(this.calendarEvents, found)
        switch (found.type) {
          case 1:
            this.regularDaysRemain++
            break
          case 2:
            this.restDaysRemain++
            break
          case 3:
            this.holidaysRemain++
            break
        }
      } else {
        let type =
          (this.row.shift.is_rest_holiday && find(this.holidays, { date: val.dateStr })) ||
          (this.row.shift.is_rest_holiday && this.regularDaysRemain < 1 && this.restDaysRemain < 1)
            ? 3
            : this.regularDaysRemain > 0
            ? 1
            : 2
        this.calendarEvents.push({
          id: null,
          type: type,
          start: val.dateStr,
          allDay: true,
          rendering: 'background',
          className: 'bg-danger',
        })
        switch (type) {
          case 1:
            this.regularDaysRemain--
            break
          case 2:
            this.restDaysRemain--
            break
          case 3:
            this.holidaysRemain--
            break
        }
      }
    },
    format,
  },
}
</script>
