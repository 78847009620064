import axios from '@/plugins/axios'

const url = 'annuals'
const annual = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_ANNUAL: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }, params) {
      let { data } = await axios.get(`/${url}`, { params })
      commit('SET_ANNUAL', data)
      return data
    },
    // async fetchPage({}, params) {
    //
    //     let { data } = await axios.get(`/${url}`, { params })
    //     return data
    // },
    async fetchOne({}, id) {
      let { data } = await axios.get(`/${url}/${id}`)
      return data
    },
    async doCreate({}, params) {
      let { data } = await axios.post(`/${url}`, params)
      return data
    },
    async doUpdate({}, params) {
      let { data } = await axios.put(`/${url}/${params.id}`, params)
      return data
    },
    async doDelete({}, id) {
      let { data } = await axios.delete(`/${url}/${id}`)
      return data
    },
  },
  getters: {
    all: (state) => state.data,
  },
}

export default annual
