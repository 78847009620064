<template>
  <el-dialog :visible.sync="isVisible" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">請假證明</h3>
    </template>
    <div class="row">
      <div v-for="(media, index) in pictures" :key="index" class="col-md-6">
        <img v-img:pictures :src="media.original_url" class="img-thumbnail" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { calculateWidth } from '@/plugins/mixins'
export default {
  name: 'LeaveApplyProof',
  mixins: [calculateWidth],
  props: {
    pictures: {
      type: Array,
      default() {
        return []
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.visible,
      modalMaxWidth: 800,
    }
  },
  watch: {
    visible() {
      this.isVisible = this.visible
    },
    isVisible(val) {
      this.$emit('update:visible', val)
    },
  },
}
</script>
