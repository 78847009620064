<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + '部門' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6" label="部門名稱" prop="name">
                <el-input v-model.trim="row.name" placeholder="部門名稱" />
              </el-form-item>
              <el-form-item class="col-md-6" label="核假人" prop="audit_id">
                <el-tooltip content="如無核假人，則由父系（上層）接管核假" placement="top">
                  <el-select v-model.number="row.audit_id" placeholder="- 請選擇核假人 -" clearable>
                    <el-option
                      v-for="(item, index) in $store.getters['user/department'](row.id)"
                      :key="index"
                      :label="item.name"
                      :value="Number(item.id)"
                    />
                  </el-select>
                </el-tooltip>
              </el-form-item>
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model="row.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'DepartmentSetting',
  mixins: [modify],
  data() {
    return {
      row: {
        id: null,
        parent_id: null,
        audit_id: null,
        name: null,
        comment: null,
      },
      rules: {
        name: [{ required: true, message: '請輸入部門名稱', trigger: 'blur' }],
      },
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('department', ['fetchOne', 'doCreate', 'doUpdate']),
    init(id = null, parentId = null) {
      this.submiting = false
      const action = id !== null ? this.onUpdate : this.onCreate
      action(id, parentId)
    },
    resetRow() {
      this.row = {
        id: null,
        parent_id: null,
        name: null,
        comment: null,
      }
    },
    onCreate(id, parentId) {
      this.formAction = 'create'
      this.resetRow()
      this.onAfterCreate()
      this.resetForm()
      this.$nextTick(() => {
        this.row.parent_id = parentId
        this.modalShow = true
      })
    },
  },
}
</script>
