import axios from 'axios'
import qs from 'qs'
import store from '@/store'

// import { Notification, Message } from 'element-ui'

const AxiosInstance = axios.create({
  baseURL: '/api',
  timeout: 15000,
  // responseType: 'json',
  // withCredentials: true,
  headers: {
    common: {
      // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
})

AxiosInstance.interceptors.request.use(
  (config) => {
    // 序列化
    if (['post', 'put', 'delete'].includes(config.method.toLowerCase)) {
      config.data = qs.stringify(config.data)
    }
    // token
    if (store.getters['auth/token']) {
      config.headers.common['Authorization'] = store.getters['auth/token']
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  (response) => {
    const newToken = response.headers?.authorization
    if (newToken) {
      console.log(newToken)
      store.dispatch('auth/refreshToken', newToken)
    }
    return response
  },
  (error) => {
    const { status, data } = error.response

    // token 過期了或是有問題
    if ([401, 500].includes(status) && data.message.toLowerCase().includes('token')) {
      console.log(data.message)
      store.dispatch('auth/resetAuth', null, { root: true })
      location.assign('/login')
    }

    console.log(status, data)
    return Promise.reject(error.response)
  }
)

export default AxiosInstance
