import axios from '@/plugins/axios'

const url = 'annual-leaves'
const annual = {
  namespaced: true,
  actions: {
    // async fetchAll({ commit }, params) {
    //
    //     let { data } = await axios.get(`/${url}/all`, { params })
    //     return data
    // },
    async fetchMyAnnual({}) {
      let { data } = await axios.get(`/${url}/me`)
      return data
    },
    async fetchPage({}, params) {
      let { data } = await axios.get(`/${url}`, { params })
      return data
    },
    async fetchList({}, id) {
      let { data } = await axios.get(`/${url}/list/${id}`)
      return data
    },
    async fetchOne({}, id) {
      let { data } = await axios.get(`/${url}/${id}`)
      return data
    },
    async doUpdate({}, params) {
      let { data } = await axios.put(`/${url}/${params.id}`, params)
      return data
    },
  },
}

export default annual
