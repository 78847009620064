<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">變更計算班別</h3>
      </template>
      <el-form ref="modalForm" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="alert alert-warning" role="alert">
              請注意，變更計算班別<strong>「並不會」</strong>改變員工目前的班別，只會更改<strong>「該月出勤」</strong>的計算方式。
            </div>
            <div class="form-row">
              <el-form-item class="col-md-6" label="員工" prop="user_id">
                <el-select v-model.number="row.user_id" placeholder="- 請選擇員工 -" clearable filterable>
                  <el-option
                    v-for="u in $store.getters['user/apply']"
                    :key="u.id"
                    :label="u.name"
                    :value="Number(u.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="col-md-6" label="月份" prop="month">
                <el-date-picker
                  v-model="row.month"
                  type="month"
                  placeholder="選擇月份"
                  value-format="yyyy-MM"
                  :clearable="false"
                />
              </el-form-item>
              <el-form-item class="col-md-6" label="班別" prop="shift_id">
                <el-select v-model.number="row.shift_id" placeholder="- 請選擇 -">
                  <el-option
                    v-for="item in $store.getters['shift/all']"
                    :key="item.id"
                    :label="item.name"
                    :value="Number(item.id)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="shift?.is_serial"
                class="col-md-6"
                label="班次"
                prop="serial"
                :rules="[{ required: shift?.is_serial, message: '請選擇班次', trigger: 'change' }]"
              >
                <el-select v-model.number="row.serial" placeholder="- 請選擇 -">
                  <el-option v-for="(item, s) in shift.period" :key="s" :label="item.name" :value="s" />
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="modalShow = false">
              <fa icon="times-circle" fixed-width />取消
            </b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />變更
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { calculateWidth } from '@/plugins/mixins'
import { mapActions } from 'vuex'

export default {
  name: 'ChangeShift',
  mixins: [calculateWidth],
  props: {
    userId: {
      type: Number,
      default: null,
    },
    month: {
      type: String,
      default: format(new Date(), 'YYYY-MM'),
    },
  },
  data() {
    return {
      row: {
        user_id: null,
        month: null,
        shift_id: null,
        serial: 0,
      },
      rules: {
        user_id: [{ required: true, message: '請選擇員工', trigger: 'change' }],
        month: [{ required: true, message: '請選擇月份', trigger: 'change' }],
        shift_id: [{ required: true, message: '請選擇班別', trigger: 'change' }],
      },
      modalShow: false,
      submiting: false,
      modalMaxWidth: 800,
    }
  },
  computed: {
    shift() {
      return this.$store.getters['shift/all'].find((s) => s.id === this.row.shift_id)
    },
  },
  methods: {
    ...mapActions('shift', ['doChange']),
    init() {
      this.submiting = false
      this.$set(this.$data, 'row', {
        user_id: this.userId,
        month: this.month,
        shift_id: null,
      })
      this.$refs.modalForm?.resetFields()
      this.modalShow = true
    },
    onSubmit() {
      this.submiting = true
      this.$nextTick().then(() => {
        this.$refs.modalForm.validate((valid) => {
          if (valid) {
            this.doChange(this.row)
              .then(() => {
                this.$message.success('該月計算班別變更完成')
                this.modalShow = false
                this.$emit('done')
              })
              .catch(({ data }) => {
                this.submiting = false
                this.$message.error(data.message)
              })
          } else {
            this.$message.error('請重新確認輸入欄位')
            this.submiting = false
            return false
          }
        })
      })
    },
  },
}
</script>
