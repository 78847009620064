import Vue from 'vue'
// import BootstrapVue from 'bootstrap-vue'
import {
  PaginationPlugin,
  PaginationNavPlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  BadgePlugin,
  DropdownPlugin,
} from 'bootstrap-vue'

// Vue.use(BootstrapVue)
Vue.use(PaginationPlugin)
Vue.use(PaginationNavPlugin)
Vue.use(ButtonPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(BadgePlugin)
Vue.use(DropdownPlugin)
