<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + '刷卡節點' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6" label="節點名稱" prop="name">
                <el-input v-model.trim="row.name" placeholder="節點名稱" />
              </el-form-item>
              <el-form-item class="col-md-6" label="IP" prop="ip">
                <el-input v-model.trim="row.ip" placeholder="IP" />
              </el-form-item>
              <el-form-item class="col-md-6" label="Port" prop="port">
                <el-input v-model.number="row.port" type="number" :min="1" :max="65535" placeholder="Port" />
              </el-form-item>
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model="row.comment" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'

export default {
  name: 'NodeSetting',
  mixins: [modify],
  data() {
    return {
      row: {
        id: null,
        name: null,
        ip: null,
        port: null,
        comment: null,
      },
      rules: {
        name: [{ required: true, message: '請輸入節點名稱', trigger: 'blur' }],
        ip: [
          { required: true, message: '請輸入節點 IP', trigger: 'blur' },
          {
            pattern: /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,
            message: '輸入 IP 不正確',
            trigger: 'change',
          },
        ],
        port: [
          { required: true, message: '請輸入節點 Port', trigger: 'blur' },
          { type: 'number', min: 1, max: 65535, message: '輸入 Port 不正確', trigger: 'change' },
        ],
      },
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('node', ['fetchOne', 'doCreate', 'doUpdate']),
    resetRow() {
      this.row = {
        id: null,
        name: null,
        ip: null,
        port: null,
        comment: null,
      }
    },
  },
}
</script>
