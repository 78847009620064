<template>
  <div class="d-flex flex-row flex-wrap align-items-center">
    <b-pagination-nav
      v-model="page"
      :number-of-pages="pagination.last_page"
      :link-gen="linkGen"
      :limit="isMobile ? 5 : 10"
      use-router
      size="md"
      class="mr-3 mb-3"
    />
    <nav class="mb-3">
      <ul class="pagination pagination-md mb-0">
        <li class="page-item disabled">
          <span class="page-link text-nowrap">共 {{ pagination.last_page }} 頁 ( {{ pagination.total }} 項 )</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { omitBy } from 'lodash-es'
export default {
  name: 'PaginationNav',
  props: {
    query: {
      type: Object,
      default() {
        return {
          page: 1,
        }
      },
    },
    pagination: {
      type: Object,
      default() {
        return {
          current_page: 1,
          last_page: 1,
          total: 0,
        }
      },
    },
  },
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.app.device === 'mobile'
    },
  },
  watch: {
    'pagination.current_page': {
      handler(newVal) {
        this.$nextTick(() => {
          this.$set(this.$data, 'page', newVal)
        })
      },
      immediate: false,
    },
  },
  methods: {
    linkGen(page) {
      let query = omitBy(this.query, (p) => p === '' || p === null)
      query.page = page || 1
      return { query }
    },
  },
}
</script>
