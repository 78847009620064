import axios from '@/plugins/axios'

const url = 'attendances'
const attendance = {
  namespaced: true,
  actions: {
    async fetchDaily({}, date) {
      let { data } = await axios.get(`/${url}/daily/${date}`)
      return data
    },
    async fetchMonthly({}, date) {
      let { data } = await axios.get(`/${url}/monthly/${date}`)
      return data
    },
    async fetchMonthlyStatus({}, date) {
      let { data } = await axios.get(`/${url}/monthly-status/${date}`)
      return data
    },
    async fetchMonthlyByUserId({}, { date, id }) {
      let { data } = await axios.get(`/${url}/monthly/${date}/employee/${id}`)
      return data
    },
    async fetchMyAttendance({ rootGetters }, date) {
      let id = rootGetters['auth/user'].id
      let { data } = await axios.get(`/${url}/monthly/${date}/employee/${id}`)
      return data
    },
    async fetchByDepartment({}, month) {
      let { data } = await axios.get(`/${url}/department/${month}`)
      return data
    },
    async fetchMonthlySerial({}, date) {
      let { data } = await axios.get(`/${url}/monthly-serial/${date}`)
      return data
    },
    async fetchMonthlySerialByUserId({}, { date, id }) {
      let { data } = await axios.get(`/${url}/monthly-serial/${date}/employee/${id}`)
      return data
    },
    async fetchMonthlySerialByDepartment({}, month) {
      let { data } = await axios.get(`/${url}/department-serial/${month}`)
      return data
    },
    async fetchYearly({}, { year, half }) {
      let { data } = await axios.get(`/${url}/yearly/${year}/${half}`)
      return data
    },
    async fetchYearlyByUserId({}, { year, id }) {
      let { data } = await axios.get(`/${url}/yearly/${year}/employee/${id}`)
      return data
    },
    async getSerial({}, { userId, date }) {
      let { data } = await axios.get(`/${url}/serial/${userId}/${date}`)
      return data
    },
    async doGenerateMonthly({}, date) {
      let { data } = await axios.post(`/${url}/generate/monthly/${date}`, {}, { timeout: 0 })
      return data
    },
    async doGenerateMonthlyByUserId({}, { date, id }) {
      let { data } = await axios.post(`/${url}/generate/monthly/${date}/employee/${id}`, {}, { timeout: 0 })
      return data
    },
    async doChangeSerial({}, id) {
      let { data } = await axios.put(`/${url}/change-serial/${id}`)
      return data
    },
    async doUpdateSerial({}, params) {
      await axios.put(`/${url}/update-serial/`, params)
    },
  },
}

export default attendance
