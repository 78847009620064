import axios from '@/plugins/axios'

const url = 'bulletin-boards'
const bulletinBoard = {
  namespaced: true,
  actions: {
    async fetchPublished({}, params) {
      let { data } = await axios.get(`/${url}/published/${params.id}`, { params })
      return data
    },
    async fetchPage({}, params) {
      let { data } = await axios.get(`/${url}`, { params })
      return data
    },
    async fetchOne({}, id) {
      let { data } = await axios.get(`/${url}/${id}`)
      return data
    },
    async doCreate({}, params) {
      let { data } = await axios.post(`/${url}`, params)
      return data
    },
    async doUpdate({}, params) {
      let { data } = await axios.put(`/${url}/${params.id}`, params)
      return data
    },
    async doDelete({}, id) {
      let { data } = await axios.delete(`/${url}/${id}`)
      return data
    },
  },
}

export default bulletinBoard
