<template>
  <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
    <template #title>
      <h3 class="m-0">
        {{ '修改特休假' }}
      </h3>
    </template>
    <el-form :ref="formRefs" :model="row" :rules="rules" label-width="120px">
      <div class="card">
        <div class="card-body p-3">
          <div class="form-row">
            <el-form-item class="col-md-6 col-lg-4" label="員工姓名">
              <h6>{{ row.user && row.user.name }}</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="年資">
              <h6>
                {{ row.user && Math.floor(row.user.seniority / 12) + ' 年 ' + (row.user.seniority % 12) + ' 月' }}
              </h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="可休天數">
              <h6>{{ row.days }} 天</h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-12" label="起訖時間">
              <h6 class="text-monospace">
                {{ format(row.created_at, 'YYYY-MM-DD') }} ~
                {{ format(row.deadline, 'YYYY-MM-DD') }}
              </h6>
            </el-form-item>
            <div class="w-100"></div>
            <el-form-item class="col-md-6 col-lg-4" label="上年度累計" prop="accumulated">
              <el-input v-model.number="row.accumulated" type="number" :min="0" placeholder="上年度累計">
                <template #append>小時</template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="本年度時數" prop="hours">
              <el-input v-model.number="row.hours" type="number" :min="0" placeholder="本年度時數">
                <template #append>小時</template>
              </el-input>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="可休總時數">
              <h6>{{ totalHours }} 小時</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="已休總時數">
              <h6>{{ row.used_hours }} 小時</h6>
            </el-form-item>
            <el-form-item class="col-md-6 col-lg-4" label="未休總時數">
              <h6>{{ totalHours - row.used_hours }} 小時</h6>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="col text-right">
          <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
          <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
            <fa icon="edit" fixed-width />{{ actionText }}
          </b-btn>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { format } from 'date-fns'

export default {
  name: 'AnnualLeaveSetting',
  mixins: [modify],
  data() {
    return {
      rules: {
        accumulated: [{ required: true, type: 'number', min: 0, message: '請確認上年度累計時數', trigger: 'blur' }],
        hours: [{ required: true, type: 'number', min: 0, message: '請確認上年度累計時數', trigger: 'blur' }],
      },
      modalMaxWidth: 900,
    }
  },
  computed: {
    totalHours() {
      return this.row.accumulated + this.row.hours || 0
    },
  },
  methods: {
    ...mapActions('annualLeave', ['fetchOne', 'doUpdate']),
    format,
  },
}
</script>
