/**
 * color themes
 */
export const colors = [
  'danger',
  'success',
  'orange',
  'primary',
  'info',
  'indigo',
  'pink',
  'teal',
  'warning',
  'secondary',
  'dark',
  'purple',
]

export const mdColors = [
  'error',
  'success',
  'orange',
  'primary',
  'info',
  'indigo',
  'pink',
  'teal',
  'warning',
  'secondary',
  'dark',
  'purple',
]

export const shiftTypes = {
  0: '排班制',
  1: '依行事曆',
  2: '時薪制',
}

export const userStatus = {
  1: '在職中',
  2: '留職停薪',
  3: '已離職',
}

export const holidayTypes = {
  0: '平日',
  1: '例假日',
  2: '休息日',
  3: '休假日（國定假日、特休假）',
  4: '天災假（颱風假、暴雨假等）',
}

export const accessTypes = {
  1: '上班',
  2: '下班',
  3: '加班開始',
  4: '加班結束',
}

export const shiftGrandTotal = {
  0: '累計',
  1: '補薪',
  2: '不累計、不補薪',
}

export const leaveApplyStatus = {
  0: '駁回',
  1: '核准',
  2: '申請中',
  3: '審核中',
}

export const leaveAuditStatus = {
  0: '駁回',
  1: '核准',
  2: '審核中',
}
