import axios from '@/plugins/axios'

const url = 'arranges'
const arrange = {
  namespaced: true,
  actions: {
    async fetchMonthly({}, month) {
      let { data } = await axios.get(`/${url}/monthly/${month}`)
      return data
    },
    async fetchMyArrange({}, month) {
      let { data } = await axios.get(`/${url}/me/${month}`)
      return data
    },
    async fetchByDepartment({}, month) {
      let { data } = await axios.get(`/${url}/department/${month}`)
      return data
    },
    async fetchMonthlyByUserId({}, { date, id }) {
      let { data } = await axios.get(`/${url}/monthly/${date}/employee/${id}`)
      return data
    },
    async fetchOne({}, id) {
      let { data } = await axios.get(`/${url}/${id}`)
      return data
    },
    async doCreate({}, params) {
      let { data } = await axios.post(`/${url}`, params)
      return data
    },
    async doUpdate({}, params) {
      let { data } = await axios.put(`/${url}/${params.id}`, params)
      return data
    },
    async doDelete({}, id) {
      let { data } = await axios.delete(`/${url}/${id}`)
      return data
    },
  },
}

export default arrange
