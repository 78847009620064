<template>
  <div>
    <el-dialog
      :visible.sync="modalShow"
      :close-on-click-modal="false"
      :width="modalWidth"
      top="5px"
      append-to-body
      @open="modalOpen"
    >
      <template #title>
        <h3 class="m-0">
          {{ actionText + '行事曆' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6" label="節日名稱" prop="name">
                <el-input v-model.trim="row.name" placeholder="節日名稱" />
              </el-form-item>
              <el-form-item class="col-md-6" label="日期" prop="date">
                <el-date-picker
                  v-model="row.date"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="日期"
                />
              </el-form-item>
              <el-form-item class="col-md-6" label="提早下班" prop="is_early">
                <el-switch
                  v-model="row.is_early"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item
                v-if="row.is_early"
                class="col-md-6"
                label="下班時間"
                prop="early_at"
                :rules="[{ required: row.is_early, message: '請輸入提早下班時間', trigger: 'blur' }]"
              >
                <el-time-select
                  ref="earlyAt"
                  v-model="row.early_at"
                  :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }"
                  placeholder="提早下班時間"
                ></el-time-select>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col-md-6" label="是否放假" prop="is_holiday">
                <el-switch
                  v-model="row.is_holiday"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="是"
                  inactive-text="否"
                />
              </el-form-item>
              <el-form-item
                v-if="row.is_holiday"
                class="col-md-6"
                label="種類"
                prop="type"
                :rules="[{ required: row.is_holiday, message: '請選擇種類', trigger: 'change' }]"
              >
                <el-select v-model.number="row.type" placeholder="- 請選擇 -">
                  <el-option v-for="(item, index) in holidayTypes" :key="index" :label="item" :value="Number(index)" />
                </el-select>
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model="row.comment" type="textarea" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div v-if="row.id" class="col">
            <b-btn :disabled="submiting" variant="danger" @click.prevent="onDelete(row.id)">
              <fa icon="edit" fixed-width />刪除
            </b-btn>
          </div>
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { holidayTypes } from '@/plugins/const'
import { modify } from '@/plugins/mixins'

export default {
  name: 'HolidaySetting',
  mixins: [modify],
  props: {
    current: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      row: {
        id: null,
        name: null,
        date: null,
        type: 4,
        is_holiday: 1,
        is_early: 0,
        early_at: null,
        comment: null,
      },
      rules: {
        name: [{ required: true, message: '請輸入節點名稱', trigger: 'blur' }],
        date: [{ required: true, message: '請輸入日期', trigger: 'blur' }],
        is_holiday: [{ required: true, message: '請選擇是否放假', trigger: 'change' }],
        is_early: [{ required: true, message: '請選擇是否提早下班', trigger: 'change' }],
      },
      modalMaxWidth: 800,
      holidayTypes,
    }
  },
  watch: {
    current(val) {
      this.row.date = format(val, 'YYYY-MM-DD')
    },
  },
  methods: {
    ...mapActions('holiday', ['fetchOne', 'doCreate', 'doUpdate', 'doDelete']),
    resetRow() {
      this.row = {
        id: null,
        name: null,
        type: 4,
        date: format(this.current, 'YYYY-MM-DD'),
        is_holiday: 1,
        is_early: 0,
        early_at: null,
        comment: null,
      }
    },
    onDelete(id, text = '刪除') {
      this.$confirm(`是否確定${text}？`, '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doDelete(id).then(() => {
            this.$message.success(`${text}完成`)
            this.submiting = false
            this.modalShow = false
            this.$emit('done')
          })
        })
        .catch(() => {
          this.$message.info(`刪除已取消`)
        })
    },
    modalOpen() {
      // 修正 el-time-select 怪問題
      if (this.$refs.earlyAt) {
        this.$refs.earlyAt.userInput = null
        this.$refs.earlyAt.valueOnOpen = null
      }
    },
  },
}
</script>
