import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import { sync } from 'vuex-router-sync'
import { Message } from 'element-ui'
import store from '@/store'
import Layout from './Layout.vue'
import qs from 'qs'
import { omitBy } from 'lodash-es'

Vue.use(Router)
NProgress.configure({ showSpinner: false })

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  parseQuery(query) {
    return qs.parse(query)
  },
  stringifyQuery(query) {
    return qs.stringify(
      omitBy(query, (p) => p === '' || p === null),
      { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' }
    )
  },
  routes: [
    {
      name: 'Home',
      path: '',
      component: Layout,
      redirect: '/dashboard',
      meta: {},
      children: [
        {
          name: 'DashboardIndex',
          path: '/dashboard',
          component: () => import(/* webpackChunkName: "basic" */ '@/views/Dashboard/Index'),
          meta: { title: '首頁', icon: 'home', cache: true },
        },
      ],
    },
    {
      // 登入
      name: 'Login',
      path: '/login',
      component: () => import(/* webpackChunkName: "basic" */ '@/views/Auth/Login'),
      meta: { title: '登入', hidden: true },
    },
    {
      // 登出
      name: 'Logout',
      path: '/logout',
      component: () => import(/* webpackChunkName: "basic" */ '@/views/Auth/Logout'),
      meta: { title: '登出', hidden: true },
    },
    {
      // page 404
      name: 'Page404',
      path: '/404',
      component: () => import(/* webpackChunkName: "basic" */ '@/views/Errors/404'),
      meta: { title: '404', hidden: true },
    },
    {
      name: 'Attendance',
      path: '',
      component: Layout,
      meta: {},
      children: [
        {
          name: 'AttendanceIndex',
          path: '/attendance',
          redirect: '/attendance/daily',
          component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/Index'),
          meta: { title: '出勤記錄', icon: 'clipboard-check', breadcrumb: false },
          children: [
            {
              name: 'AttendanceDaily',
              path: '/attendance/daily/:date?',
              component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/AttendanceDaily'),
              meta: { title: '日報表', hidden: true },
            },
            {
              name: 'AttendanceMonthly',
              path: '/attendance/monthly/:date?',
              component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/AttendanceMonthly'),
              meta: { title: '月報表', hidden: true },
            },
            {
              name: 'AttendanceMonthlyStatus',
              path: '/attendance/monthly-status/:date?',
              component: () =>
                import(/* webpackChunkName: "attendance" */ '@/views/Attendance/AttendanceMonthlyStatus'),
              meta: { title: '月報表', hidden: true },
            },
            {
              name: 'UserMonthly',
              path: '/attendance/monthly/:date/employee/:user_id',
              component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/UserMonthly'),
              meta: { title: '個人月報表', hidden: true },
            },
            {
              name: 'AttendanceYearly',
              path: '/attendance/yearly/:year?',
              component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/AttendanceYearly'),
              meta: { title: '年度報表', hidden: true },
            },
            {
              name: 'UserYearly',
              path: '/attendance/yearly/:year/employee/:user_id',
              component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/UserYearly'),
              meta: { title: '個人年報表', hidden: true },
            },
          ],
        },
      ],
    },
    {
      name: 'Arrange',
      path: '',
      component: Layout,
      meta: {},
      children: [
        {
          name: 'ArrangeIndex',
          path: '/arrange',
          redirect: '/arrange/monthly',
          component: () => import(/* webpackChunkName: "arrange" */ '@/views/Arrange/Index'),
          meta: { title: '排休管理', icon: 'user-clock', breadcrumb: false },
          children: [
            {
              name: 'ArrangeMonthly',
              path: '/arrange/monthly/:date?',
              component: () => import(/* webpackChunkName: "arrange" */ '@/views/Arrange/ArrangeMonthly'),
              meta: { title: '月排休表', hidden: true, cache: true },
            },
            {
              name: 'ArrangeMonthlySerial',
              path: '/arrange/monthly/:date?/serial',
              component: () => import(/* webpackChunkName: "arrange" */ '@/views/Arrange/ArrangeMonthlySerial'),
              meta: { title: '多段班次排班表', hidden: true, cache: true },
            },
          ],
        },
      ],
    },
    {
      name: 'Holiday',
      path: '',
      component: Layout,
      meta: {},
      children: [
        {
          name: 'HolidayIndex',
          path: '/holiday',
          component: () => import(/* webpackChunkName: "holiday" */ '@/views/Holiday/Index'),
          meta: { title: '行事曆', icon: 'calendar-alt' },
        },
      ],
    },
    {
      name: 'User',
      path: '',
      component: Layout,
      meta: {},
      children: [
        {
          name: 'UserIndex',
          path: '/employee',
          component: () => import(/* webpackChunkName: "employee" */ '@/views/User/Index'),
          meta: { title: '員工資料', icon: 'address-card', cache: true },
        },
      ],
    },
    {
      name: 'LeaveApply',
      path: '',
      component: Layout,
      meta: {},
      children: [
        {
          name: 'LeaveApplyIndex',
          path: '/leave-applies',
          component: () => import(/* webpackChunkName: "leave-apply" */ '@/views/LeaveApply/Index'),
          meta: { title: '請休假管理', icon: 'book-medical', cache: true },
        },
        {
          name: 'AnnualLeaveIndex',
          path: '/annual-leaves',
          component: () => import(/* webpackChunkName: "leave-apply" */ '@/views/AnnualLeave/Index'),
          meta: { title: '特休假管理', hidden: true, cache: true },
        },
      ],
    },
    {
      name: 'Setting',
      path: '',
      component: Layout,
      meta: { title: '設定', icon: 'cogs', breadcrumb: false },
      children: [
        {
          name: 'NodeIndex',
          path: '/node',
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Node/Index'),
          meta: { title: '刷卡節點', icon: 'code-branch', cache: true },
        },
        {
          name: 'DepartmentIndex',
          path: '/department',
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Department/Index'),
          meta: { title: '組織部門', icon: 'project-diagram', cache: true },
        },
        {
          name: 'ShiftIndex',
          path: '/shifts',
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Shift/Index'),
          meta: { title: '出勤班別', icon: 'chalkboard-teacher', cache: true },
        },
        {
          name: 'LeaveIndex',
          path: '/leaves',
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Leave/Index'),
          meta: { title: '請假假別', icon: 'sign-out-alt', cache: true },
        },
        {
          name: 'AnnualIndex',
          path: '/annuals',
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Annual/Index'),
          meta: { title: '特別休假', icon: 'plane-departure', cache: true },
        },
      ],
    },
    { path: '*', redirect: '/404', meta: { hidden: true } },
  ],
})

sync(store, router)

const whiteList = ['/login']
router.beforeEach(async (to, from, next) => {
  // console.log(to, from)
  NProgress.start()

  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 是否有 token
  if (!store.getters['auth/token']) {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    if (to.path === '/login') {
      next({ path: '/dashboard' })
    } else {
      // 如果 vuex state 沒有 user 的資料，取得資料
      if (!store.getters['auth/check']) {
        try {
          await store.dispatch('auth/fetchUser')
          // 檢查是否可以登入後端
          if (!store.getters['auth/isAdmin']) {
            window.location.replace('/mobile')
          }
          next({ ...to, replace: true })
        } catch (err) {
          console.log(err)
          await store.dispatch('auth/resetAuth')
          Message.error('登入驗證失敗，請重新登入')
          next({ path: '/login' })
        }
      } else {
        // 檢查是否可以登入後端
        if (!store.getters['auth/isAdmin']) {
          window.location.replace('/mobile')
        }
        next()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export { router, store }
