<template>
  <div>
    <el-dialog :visible.sync="modalShow" :close-on-click-modal="false" :width="modalWidth" top="5px" append-to-body>
      <template #title>
        <h3 class="m-0">
          {{ actionText + '打卡記錄' }}
        </h3>
      </template>
      <el-form :ref="formRefs" :model="row" :rules="rules" label-width="100px">
        <div class="card">
          <div class="card-body p-3">
            <div class="form-row">
              <el-form-item class="col-md-6" label="員工姓名" prop="user_id">
                <h6>{{ userInfo.name }}</h6>
              </el-form-item>
              <el-form-item class="col-md-6" label="打卡種類" prop="type">
                <el-select v-model="row.type" placeholder="打卡種類">
                  <el-option v-for="(item, index) in accessTypes" :key="index" :label="item" :value="Number(index)" />
                </el-select>
              </el-form-item>
              <div class="el-form-item col-md-6">
                <label class="el-form-item__label" style="width: 100px">打卡日期</label>
                <div class="el-form-item__content" style="margin-left: 100px">
                  <h6>
                    {{ format(row.access_at, 'YYYY-MM-DD') }}
                    <span v-if="[2, 3, 4].includes(row.type)">
                      （
                      <el-checkbox v-model="is_over" style="line-height: 1.5; margin: 0" @change="onOver">
                        隔夜
                      </el-checkbox>
                      ）
                    </span>
                  </h6>
                </div>
              </div>
              <el-form-item class="col-md-6" label="打卡時間" prop="access_at">
                <el-time-picker v-model="row.access_at" placeholder="打卡時間" @input="onTimeChange" />
              </el-form-item>
              <div class="w-100"></div>
              <el-form-item class="col" label="備註" prop="comment">
                <el-input v-model="row.comment" type="textarea" placeholder="備註" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col text-right">
            <b-btn variant="secondary" @click.prevent="hideModal"><fa icon="times-circle" fixed-width />取消</b-btn>
            <b-btn :disabled="submiting" type="submit" variant="primary" @click.prevent="onSubmit">
              <fa icon="edit" fixed-width />{{ actionText }}
            </b-btn>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { modify } from '@/plugins/mixins'
import { accessTypes } from '@/plugins/const'

export default {
  name: 'AccessLogSetting',
  mixins: [modify],
  props: {
    userInfo: {
      type: Object,
      default() {
        return {
          id: null,
          name: null,
        }
      },
      required: true,
    },
    currentDate: {
      type: String,
      default: format(new Date(), 'YYYY-MM-DD'),
      required: true,
    },
  },
  data() {
    return {
      is_over: false,
      row: {
        id: null,
        node_id: null,
        type: null,
        door: 1,
        user_id: this.userInfo.id,
        card_id: null,
        is_audit: 1,
        audit_id: null,
        access_at: null,
        comment: null,
      },
      rules: {
        type: [{ required: true, message: '請選擇打卡種類', trigger: 'change' }],
        access_at: [{ required: true, message: '請選擇打卡時間', trigger: 'change' }],
      },
      accessTypes,
      modalMaxWidth: 800,
    }
  },
  methods: {
    ...mapActions('accessLog', ['fetchOne', 'doCreate', 'doUpdate']),
    resetRow() {
      this.is_over = false
      this.row = {
        id: null,
        node_id: null,
        type: null,
        door: 1,
        user_id: this.userInfo.id,
        card_id: null,
        is_audit: 1,
        audit_id: null,
        access_at: new Date(this.currentDate),
        comment: null,
      }
    },
    onOver(val) {
      if (val) {
        this.row.access_at.setDate(this.row.access_at.getDate() + 1)
      } else {
        let date = new Date(this.currentDate)
        this.row.access_at.setDate(date.getDate())
        this.row.access_at.setMonth(date.getMonth())
        this.row.access_at.setFullYear(date.getFullYear())
      }
    },
    onTimeChange() {
      // 修正 Timepicker 的問題
      let date = new Date(this.currentDate)
      this.row.access_at.setDate(date.getDate() + (this.is_over ? 1 : 0))
      this.row.access_at.setMonth(date.getMonth())
      this.row.access_at.setFullYear(date.getFullYear())
    },
    format,
  },
}
</script>
