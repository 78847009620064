import axios from '@/plugins/axios'
import Cookie from 'js-cookie'

const auth = {
  namespaced: true,
  state: {
    user: null,
    token: localStorage.getItem('token') || null,
  },
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data.access_token
      state.user = data.user
      localStorage.setItem('token', data.access_token)
      // 開發時使用 cookie
      Cookie.set('token', data.access_token.split(' ')[1])
    },

    REFRESH_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem('token', token)
      // 開發時使用 cookie
      Cookie.set('token', token.split(' ')[1])
    },

    SET_USER: (state, data) => {
      state.user = data
    },

    RESET_AUTH: (state) => {
      state.user = null
      state.token = null
      localStorage.removeItem('token')
      // 開發時使用 cookie
      Cookie.remove('token')
    },
  },
  actions: {
    // 登入
    async login({ commit }, credential) {
      try {
        let { data } = await axios.post('/auth/login', credential)
        await commit('SET_TOKEN', data)
        return data
      } catch (err) {
        await commit('RESET_AUTH')
        throw err
      }
    },

    async logout({ commit }) {
      let result = await axios.post('/auth/logout')
      await commit('RESET_AUTH')
      return result
    },

    async resetPassword({}, params) {
      let { data } = await axios.post('/auth/reset-password', params)

      return data
    },

    async setFcmToken({}, params) {
      let { data } = await axios.put('/auth/fcm', params)

      return data
    },

    async refreshToken({ commit }, token) {
      await commit('REFRESH_TOKEN', token)
      return true
    },

    async resetAuth({ commit }) {
      await commit('RESET_AUTH')
      // await commit('permission/RESET_ROUTERS', null, { root: true })
      return true
    },

    async fetchUser({ commit }) {
      try {
        let { data } = await axios.get('/auth/me')
        await commit('SET_USER', data)
        return data
      } catch (err) {
        await commit('RESET_AUTH')
        throw err
      }
    },
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    check: (state) => state.user !== null,
    isAdmin: (state) => state.user?.is_admin,
    isArrange: (state) => state.user?.shift?.type == 0,
    isAuditor: (state) => state.user?.is_admin || state.user?.id == state.user?.department?.audit_id,
    isAttendance: (state) => state.user?.shift?.is_audit == 1,
  },
}

export default auth
