import Vue from 'vue'
// import _ from 'lodash'
import App from '@/App.vue'
import { router, store } from './router'
import VueMask from 'v-mask'
import VueImg from 'v-img'
import messaging from '@/plugins/firebase'

import '@/registerServiceWorker'
import '@/plugins/bootstrap-vue'
import '@/plugins/element-ui'
import '@/plugins/fontawesome'
import '@/plugins/sentry'
import '@/components'
import '@/scss/app.scss'

Vue.config.productionTip = false
// Vue.prototype._ = _
Vue.prototype.$messaging = messaging
Vue.use(VueMask)
Vue.use(VueImg)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
